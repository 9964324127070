import { ApolloClient } from "@apollo/client";
import Config, {
  ENV_DEMO,
  ENV_DEVELOPMENT,
  ENV_PREPROD,
  ENV_PRODUCTION,
  ENV_STAGING,
} from "core/model/config";
import { ApolloCacheData, CareseekerInRole } from "core/types";
import gql from "graphql-tag";
import { connect } from "react-redux";
import { RootState } from "reduxentities/store";
import { createSelector } from "reselect";

export const selectToken = ({ auth }: RootState) => auth?.credentials?.token;

export const selectTokenType = ({ auth }: RootState) =>
  auth?.credentials?.token_type;

export const selectVersion = ({ auth }: RootState) => auth?.version;

export const selectCredentials = ({ auth }: RootState) => auth?.credentials;

export const selectCareproviderRoles = ({ auth }: RootState) =>
  auth?.identification?.careprovider_roles;

export const selectCareseekerRoles = ({ auth }: RootState) =>
  auth?.identification?.careseeker_roles;

function careproviderFromCache(
  apolloClient: ApolloClient<ApolloCacheData>,
  careproviderId: number,
) {
  return apolloClient.readFragment({
    id: `Careprovider:${careproviderId}`,
    fragment: gql`
      fragment loggedProvider on Careprovider {
        id
      }
    `,
  });
}

export const selectLoggedCareproviderId = ({ auth }: RootState) =>
  auth?.identification ? auth.careprovider : undefined;

export const selectAndCheckLoggedCareproviderApollo = (
  state: RootState,
  careproviderId: number,
  apolloClient: ApolloClient<ApolloCacheData>,
) => {
  const cachedProvider = careproviderFromCache(apolloClient, careproviderId);
  if (!cachedProvider) return false;

  const loggedCareproviderId = selectLoggedCareproviderId(state);
  if (!loggedCareproviderId) return false;

  return cachedProvider.id == loggedCareproviderId;
};

export const selectLoggedInAccount = ({ auth }: RootState) =>
  auth?.identification?.account;

export const selectLoggedInAccountId = ({ auth }: RootState) =>
  auth?.identification?.account?.id;

export const selectLoggedCareprovider = (
  state: RootState,
  apolloClient: ApolloClient<ApolloCacheData>,
) => {
  const loggedCareproviderId = selectLoggedCareproviderId(state);

  if (!loggedCareproviderId) return null;

  const careprovider = careproviderFromCache(
    apolloClient,
    loggedCareproviderId,
  );

  if (!careprovider) return null;

  return careprovider;
};

export const selectCareseekers = createSelector(
  selectCareseekerRoles,
  (roles) => {
    if (!roles?.length) return undefined;

    return roles.reduce<CareseekerInRole[]>((acc, curr) => {
      if (curr?.careseeker) {
        return [...acc, curr.careseeker];
      }
      return acc;
    }, []);
  },
);

export const selectLoggedCareseeker = (state: RootState) => {
  const loggedCareseekerId = state.auth?.careseeker;
  if (!loggedCareseekerId) return null;

  const careseekers = selectCareseekers(state);

  if (!careseekers) return null;

  return careseekers.find(
    (careseeker) => careseeker?.id === loggedCareseekerId,
  );
};

export const selectAuthType = ({ auth }: RootState) =>
  auth?.credentials?.auth_type;

export const selectActiveRoles = ({ auth }: RootState) => auth?.active_roles;

export const selectIdentification = ({ auth }: RootState) =>
  auth?.identification;

export const selectPrivateKey = ({ auth }: RootState) =>
  auth?.identification?.privateKey;

// This hardcodes the ID of the default careprovider id per env.
export function getDefaultProviderAccountId() {
  switch (Config.environment) {
    case ENV_PRODUCTION:
      return 35;
    case ENV_PREPROD:
    case ENV_DEMO:
      return 14;
    case ENV_STAGING:
    case ENV_DEVELOPMENT:
    default:
      return 3;
  }
}

export const getCareseeker = connect((state: RootState) => {
  const careseeker = selectLoggedCareseeker(state);
  return {
    careseekerId: careseeker?.id,
    careseeker,
  };
});
