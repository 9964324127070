import { QueryHookOptions } from "@apollo/client";
import { datadogLogs } from "@datadog/browser-logs";
import { clearEncryptionCaches } from "apollo/encryption/caches";
import {
  useGetAccount,
  useGetCareprovider,
  useGetCareseeker,
} from "apollo/hooks/queries";
import { getFetchPolicy } from "apollo/hooks/utils";
import { TRACK_EVENTS } from "core/consts";
import {
  isCareseekerLogged,
  isProviderSearchLogged,
} from "core/model/accounts";
import auth from "core/model/api/endpoints/auth";
import { getTopLevelDomain } from "core/model/utils/urls";
import {
  CareproviderConfig,
  Careseeker,
  CareseekerConfig,
  SupportedLanguage,
} from "core/types";
import { useCallback } from "react";
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useDispatch, useSelector, useStore } from "react-redux";
import { useTracking } from "react-tracking";
import { CAREPROVIDER_CHANGED } from "reduxentities/slices/authSlice";
import { RootState } from "reduxentities/store";
import {
  selectActiveRoles,
  selectAuthType,
  selectLoggedCareproviderId,
  selectLoggedCareseeker,
  selectLoggedInAccount,
  selectPrivateKey,
  selectToken,
  selectTokenType,
} from "./selectors";
import { COUNTRY_CHANGED, LOGGED_OUT } from "./slices/authSlice";
import { LANGUAGE_CHANGED } from "./slices/settingsSlice";
import type { AppDispatch, AppStore } from "./store";

// Use throughout the app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
export const useAppStore = useStore.withTypes<AppStore>();

export const usePrivateKey = () => useAppSelector(selectPrivateKey);

// Careseeker
export const useLoggedCareseeker = (
  options?: QueryHookOptions<
    { careseeker: Careseeker },
    { id: number | undefined }
  >,
) => {
  const loggedCareseeker = useAppSelector(selectLoggedCareseeker);
  const [, careseeker] = useGetCareseeker(
    loggedCareseeker?.id,
    { withAdminFields: true },
    {
      ...options,
      fetchPolicy: getFetchPolicy(options?.fetchPolicy ?? "cache-only"),
    },
  );

  return careseeker;
};

export const useLoggedCareseekerConfig = (
  configProperty: keyof CareseekerConfig,
) => {
  const loggedCareseeker = useLoggedCareseeker();
  return loggedCareseeker?.config?.[configProperty];
};

export function useConnecterWithCareseeker() {
  const account = useConnecterWithLoggedAccount();
  const careseeker = useAppSelector(selectLoggedCareseeker);
  const careseekerId = careseeker?.id;
  const privateKey = usePrivateKey();
  const activeRoles = useAppSelector(selectActiveRoles);
  return {
    account,
    careseeker,
    careseekerId,
    privateKey,
    activeRoles,
  };
}
export function useIsLoggedOnClinicApp() {
  return useAppSelector(isCareseekerLogged);
}

// Careprovider
export const useLoggedCareprovider = () => {
  const careproviderId = useLoggedCareproviderId();
  const [, careprovider] = useGetCareprovider({
    id: careproviderId,
    options: { fetchPolicy: getFetchPolicy("cache-only") },
  });
  return careprovider;
};

export const useLoggedCareproviderId = () => {
  return useAppSelector(selectLoggedCareproviderId);
};

export const useLoggedCareproviderConfig = (
  configProperty: keyof CareproviderConfig,
) => {
  const loggedCareprovider = useLoggedCareprovider();
  return loggedCareprovider?.config?.[configProperty];
};

export const useCareproviderChanged = () => {
  const { trackEvent } = useTracking();
  const dispatch = useAppDispatch();

  return useCallback(
    (careproviderId: number, country?: string) => {
      trackEvent({
        name: TRACK_EVENTS.CAREPROVIDER_CHANGED,
        careprovider_id_new: careproviderId,
      });
      dispatch(CAREPROVIDER_CHANGED({ careproviderId, country }));
    },
    [dispatch, trackEvent],
  );
};

// ProviderSearch
export function useIsLoggedOnProviderSearchApp() {
  return useAppSelector(isProviderSearchLogged);
}

// Account
export function useLoggedInAccount() {
  return useAppSelector(selectLoggedInAccount);
}

export function useLoggedInAccountId() {
  return useLoggedInAccount()?.id;
}

export function useConnecterWithLoggedAccount(options?: {
  cacheOnly?: boolean;
}) {
  const accountId = useLoggedInAccountId();
  const [, account] = useGetAccount(accountId || -2, {
    fetchPolicy: options?.cacheOnly ? "cache-only" : undefined,
  });
  return account;
}

// Token, auth
export function useSelectAuthType() {
  return useAppSelector(selectAuthType);
}

export function useSelectToken() {
  return useAppSelector(selectToken);
}

export function useSelectTokenType() {
  return useAppSelector(selectTokenType);
}

export function useSelectQueryToken() {
  const tokenType = useSelectTokenType();
  const token = useSelectToken();
  return tokenType === "query" ? token : undefined;
}

export const useJwtTokenData = () => {
  const tokenType = useSelectTokenType();
  const token = useSelectToken();
  const accountId = useLoggedInAccountId();

  return {
    token: tokenType === "jwt" ? token : undefined,
    accountId,
  };
};

export function useOnLogout() {
  const { trackEvent } = useTracking();
  const dispatch = useAppDispatch();

  return useCallback(
    async (options?: { hardRefresh?: boolean; tokenExpired?: boolean }) => {
      let ssoRedirect;

      if (!options?.tokenExpired) {
        try {
          const response = await auth.logout();
          if (response?.sso_logout_url) {
            const url = new URL(response.sso_logout_url);
            // Redirect to the origin to allow the default route decide where to go
            url.searchParams.append(
              "post_logout_redirect_uri",
              window.location.origin,
            );
            ssoRedirect = url.href;
          }
        } catch (e) {
          console.error(`Logout failed: ${e}`);
        }
      }

      trackEvent({
        name: TRACK_EVENTS.LOGGED_OUT,
        type: "connect",
        token_expired: options?.tokenExpired,
        forced: options?.hardRefresh,
      });

      datadogLogs.clearUser();

      clearEncryptionCaches();
      // We will be immediately redirecting to the fusionAuth logout screen so
      // there's no need to make the UI react to the lack of login anymore. With
      // this we prevent the small flicker of the login screen that can be seen
      // before the redirection to fusionAuth
      if (!ssoRedirect) {
        dispatch(LOGGED_OUT());
      }
      if (options?.hardRefresh) {
        window.localStorage.clear();
        window.sessionStorage.clear();
        if (!ssoRedirect) {
          window.location.reload();
        }
      }

      if (ssoRedirect) {
        window.location.replace(ssoRedirect);
      }
    },
    [dispatch, trackEvent],
  );
}

// Local, language, country
export function useLocale(): SupportedLanguage {
  return useAppSelector(({ settings }) => settings?.language ?? "de");
}

export const useGetLanguage = () => {
  return useAppSelector(({ settings }) => settings?.language);
};

export const useCountry = () => {
  const country = useAppSelector(({ auth }) => auth?.country);
  return country || getTopLevelDomain();
};

export function useLanguageChange() {
  const dispatch = useAppDispatch();

  return useCallback(
    (language: SupportedLanguage) => dispatch(LANGUAGE_CHANGED(language)),
    [dispatch],
  );
}

export function useCountryChanged() {
  const dispatch = useAppDispatch();

  return useCallback(
    (country: string | undefined) => dispatch(COUNTRY_CHANGED({ country })),
    [dispatch],
  );
}
