import { Validation } from "core/types";
import React, { createContext, useContext } from "react";
import { Observable } from "rxjs";
import { FormRendererContextProps } from "./Form";
import { FormStateEvent } from "./FormEvents";
import { hideComponent } from "./StateValueHelpers";
import { Whitelist } from "./types";

export type ObservableValue = {
  clickEvent?: React.MouseEvent<HTMLButtonElement>;
  newValue?: AnyObject;
  oldValue?: AnyObject;
  paths?: string[];
  statePath?: string;
  type: FormStateEvent;
  validation?: Validation;
  value?: any;
  whitelist?: Whitelist;
};

type FormElementContext = {
  completeStatePath: string | null;
  statePath: string | null;
};

const defaultFormElementContext = {
  statePath: null,
  completeStatePath: null,
};

export const formElementContext = React.createContext<FormElementContext>(
  defaultFormElementContext,
);

export function useHideComponent(statePath: string) {
  const stateDispatcherContext = useStateDispatcherContext();

  return hideComponent(stateDispatcherContext.whitelist, statePath);
}

export function useHideComponents(statePaths: string[]) {
  const stateDispatcherContext = useStateDispatcherContext();

  return !statePaths.some(
    (s) => !hideComponent(stateDispatcherContext.whitelist, s),
  );
}

export function useFormElementContext({
  flatModel,
}: { flatModel?: boolean } = {}) {
  const context = useContext(formElementContext);
  if (flatModel) {
    return { statePath: "", completeStatePath: "" };
  }
  return context;
}

export type StateDispatcherContext = {
  onStateChange:
    | ((value: any, statePath: string, validation: any) => void)
    | null;
  rootValueChangeObs: Observable<ObservableValue> | null;
  valueChangeObs: Observable<ObservableValue> | null;
  whitelist: Whitelist | null;
};

const defaultStateDispatcher = {
  valueChangeObs: null,
  onStateChange: null,
  whitelist: null,
  rootValueChangeObs: null,
};

export const stateDispatcherContext =
  React.createContext<StateDispatcherContext>(defaultStateDispatcher);

export function useStateDispatcherContext() {
  return useContext(stateDispatcherContext);
}

export function Show({
  children,
  elementName,
}: {
  children: React.ReactNode;
  elementName: string;
}) {
  const hideComponent = useHideComponent(elementName);
  if (hideComponent) return null;

  return <>{children}</>;
}

const defaultFormRendererContext: FormRendererContextProps = {
  valueChangeObs: new Observable<ObservableValue>(),
  dirty: false,
  submit: () => {},
  resetValidation: () => {},
  onChange: () => {},
};

export const FormRendererContext = createContext<FormRendererContextProps>(
  defaultFormRendererContext,
);

export function useFormRendererContext() {
  const context = useContext(FormRendererContext);
  if (!context || context === defaultFormRendererContext) {
    throw new Error("FormRendererContext not found in the tree");
  }
  return context;
}
