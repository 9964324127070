import {
  RESPONSIVE_BREAKPOINT_MOBILE,
  RESPONSIVE_BREAKPOINT_TABLET,
} from "core/consts";
import { isDesignBatch1 } from "core/model/utils/featureFlags";
import { InputWidthType } from "core/types";
import { CSSProperties } from "react";

type PatientPageOffset = "appBar" | "patientMenu" | "patientMenuTabs";

export { RESPONSIVE_BREAKPOINT_MOBILE, RESPONSIVE_BREAKPOINT_TABLET };
export const CIRCLE_BORDER_RADIUS = "50%";
export const ROUND_BORDER_RADIUS = "50px";
export const SQUARE_BORDER_RADIUS = "5px";
export const CARD_BORDER_RADIUS = "2px"; // 2dp

export const Z_INDEX_MAX = 2147483647 as const;
export const Z_INDEX_INTERCOM = 2147483003 as const;
export const Z_INDEX_DRAWER = Z_INDEX_INTERCOM + 1;
export const Z_INDEX_DRAWER_CONTENT = Z_INDEX_DRAWER + 1;
export const Z_INDEX_PATIENT_MENU = 40 as const;
export const Z_INDEX_ASSESSMENT_CONTENT = 39 as const;
export const Z_INDEX_ASSESSMENT_STEP = 40 as const;
export const Z_INDEX_MESSENGER = 40 as const;
export const Z_INDEX_FILE_UPLOAD_STATES_BACKDROP = 49 as const;
export const Z_INDEX_APP_BAR = 50 as const;
export const Z_INDEX_CRISP = 101 as const;
export const Z_INDEX_FILE_UPLOAD_STATES = 101 as const;

const BASE_FONT_SIZE = 16;

/**
 * Converts a size in pixels to pixel units (px).
 * @param {number} size - The size in pixels.
 * @returns {string} The size in pixels with px unit.
 */
export function dp(size: number) {
  return `${size}px`;
}

/**
 * Converts a size in pixels to rem units.
 * @param {number} size - The size in pixels to convert.
 * @returns {string} The size in rem units.
 */
export function rem(size: number) {
  return `${size / BASE_FONT_SIZE}rem`;
}

/**
 * Converts a size in pixels to em units based on the context font size.
 * @param {number} size - The size in pixels to convert.
 * @param {number} [contextFontSize=BASE_FONT_SIZE] - The font size of the parent element in pixels. Defaults to BASE_FONT_SIZE.
 * @returns {string} The size in em units.
 */
export function em(size: number, contextFontSize: number = BASE_FONT_SIZE) {
  return `${size / contextFontSize}em`;
}

export function dpSpace(multiplier: number): number {
  return multiplier * 8;
}

export function space(multiplier: number): string {
  return dp(dpSpace(multiplier));
}

export const sizing = space;

export const translate = ({ x, y }: { x?: number; y?: number }) => {
  return `translate(${dp(x || 0)}, ${dp(y || 0)})`;
};

/**
 * @param all multiplies by 8
 * @returns string
 */
function space4(all: number): string;
/**
 * @param topBottom multiplies by 8
 * @param leftRight multiplies by 8
 * @returns string
 */
function space4(topBottom: number, leftRight: number): string;
/**
 * @param top multiplies by 8
 * @param leftRight multiplies by 8
 * @param bottom multiplies by 8
 * @returns string
 */
function space4(top: number, leftRight: number, bottom: number): string;
/**
 * @param top multiplies by 8
 * @param right multiplies by 8
 * @param bottom multiplies by 8
 * @param left multiplies by 8
 * @returns string
 */
function space4(
  top: number,
  right: number,
  bottom: number,
  left: number,
): string;
function space4(
  top: number,
  right?: number,
  bottom?: number,
  left?: number,
): string {
  if (top != null && right != null && bottom != null && left != null) {
    return `${space(top)} ${space(right)} ${space(bottom)} ${space(left)}`;
  } else if (top != null && right != null && bottom != null) {
    return `${space(top)} ${space(right)} ${space(bottom)}`;
  } else if (top != null && right != null) {
    return `${space(top)} ${space(right)}`;
  } else if (top != null) {
    return space(top);
  }
  return "";
}

export const margin = space4;
export const padding = space4;

export const ACP_CARD_WIDTH = sizing(75.5);

export const getResponsiveCSSMediaQueries = () => {
  return {
    mobile: `(max-width: ${dp(RESPONSIVE_BREAKPOINT_MOBILE)})`,
    tablet: `(max-width: ${dp(RESPONSIVE_BREAKPOINT_TABLET)})`,
    desktop: `(min-width: ${dp(RESPONSIVE_BREAKPOINT_TABLET + 1)})`,
  };
};

export const getResponsiveTailwindMediaQueries = () => {
  return {
    mobile: `(min-width: ${dp(0)})`,
    tablet: `(min-width: ${dp(RESPONSIVE_BREAKPOINT_MOBILE + 1)})`,
    desktop: `(min-width: ${dp(RESPONSIVE_BREAKPOINT_TABLET + 1)})`,
  };
};

export const responsiveCSS = (styling: {
  desktop?: string[];
  mobile?: string[];
  tablet?: string[];
}) => {
  const joiner = (array: string[] | null | undefined) =>
    array?.length ? array.join(";\n") : "";
  const desktopCSS = joiner(styling.desktop);
  const tabletCSS = joiner(styling.tablet || styling.desktop);
  const mobileCSS = joiner(styling.mobile || styling.tablet || styling.desktop);

  const { desktop, mobile, tablet } = getResponsiveCSSMediaQueries();

  let cssString = "";

  if (desktopCSS) {
    cssString += `@media screen and ${desktop} {
    ${desktopCSS};
  }`;
  }

  if (tabletCSS) {
    // responsiveCSS uses the approach that tablet is only for tablet
    // and not also for mobile unlike the useMedia hook
    cssString += `
  @media screen and ${tablet} and (min-width: ${dp(
    RESPONSIVE_BREAKPOINT_MOBILE,
  )}) {
    ${tabletCSS};
  }`;
  }

  if (mobileCSS) {
    cssString += `
  @media screen and ${mobile} {
    ${mobileCSS};
  }`;
  }

  return cssString.trim();
};

export const ellipsis = (props?: { maxWidth: string }) => `
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  ${props?.maxWidth ? `max-width: ${props?.maxWidth};` : ""}
`;

export const VERY_SMALL = "VERY_SMALL";
export const CHECKBOX = "CHECKBOX";
export const NESTED_CHECKBOX = "NESTED_CHECKBOX";
export const ISLAND = "ISLAND";
export const FULL_WIDTH = "FULL_WIDTH";

export const SMALL = "SMALL";
export const MEDIUM = "MEDIUM";
export const LARGE = "LARGE";

export const INPUT_WIDTH: {
  [index in InputWidthType]: number;
} = {
  CHECKBOX: 594,
  NESTED_CHECKBOX: 562,
  ISLAND: 300,
  FULL_WIDTH: 634,
  SMALL: 134,
  VERY_SMALL: 120,
};

export const inputWidth = (type: InputWidthType | undefined) => {
  if (!type) return undefined;
  return dp(INPUT_WIDTH[type]);
};
export const APP_BAR_HEIGHT = 48;
export const APP_BAR_PROVIDERSEARCH_HEIGHT = 82;

export const SEARCH_BAR_WIDTH_SMALL = 200;
export const SEARCH_BAR_WIDTH_MEDIUM = 300;

export const PATIENT_MENU_HEIGHT = 112;

const PATIENT_MENU_TABS_HEIGHT = 42;

export const CHIP_HEIGHT = 32;

export const MAX_WIDTH_DASHBOARD_CARD = sizing(180);

export const THUMBNAIL_WIDTH_MOBILE = sizing(10);
export const THUMBNAIL_WIDTH_DESKTOP = sizing(7.25);

export const TABS_HEIGHT = sizing(5.25);
export const TABS_HEIGHT_MOBILE = sizing(6.25);

export function getPatientPageOffset({
  navbarHeight,
  offsetFrom = "appBar",
}: {
  navbarHeight: number;
  offsetFrom: PatientPageOffset;
}) {
  switch (offsetFrom) {
    case "appBar":
      return isDesignBatch1() ? dp(navbarHeight) : dp(APP_BAR_HEIGHT);
    case "patientMenu":
      return isDesignBatch1()
        ? `calc(${dp(navbarHeight)} + ${dp(PATIENT_MENU_HEIGHT)})`
        : `calc(${dp(APP_BAR_HEIGHT)} + ${dp(PATIENT_MENU_HEIGHT)})`;
    case "patientMenuTabs":
      return isDesignBatch1()
        ? `calc(${dp(navbarHeight)} + ${dp(PATIENT_MENU_HEIGHT)} + ${dp(
            PATIENT_MENU_TABS_HEIGHT,
          )})`
        : `calc(${dp(APP_BAR_HEIGHT)} + ${dp(PATIENT_MENU_HEIGHT)} + ${dp(
            PATIENT_MENU_TABS_HEIGHT,
          )})`;
  }
}

export const important = (styleString: string | number) =>
  `${styleString}!important`;

export const GENERAL_TABLE_TOP_MARGIN = 16;

export const border = ({
  color,
  important = false,
  style = "solid",
  width = 1,
}: {
  color: CSSProperties["borderColor"];
  important?: boolean;
  style?: CSSProperties["borderStyle"];
  width?: number;
}) => `${dp(width)} ${style} ${color}${important ? "!important" : ""}`;
