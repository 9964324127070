import { ApolloError, useApolloClient } from "@apollo/client";
import { DialogContentText, Paper, TableContainer } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { useSealdGroupReset } from "apollo/hooks/mutations";
import { useSealdGroupSessionsCheck } from "apollo/hooks/queries";
import { composeProgress } from "apollo/utils";
import { UPDATE_ACCOUNT } from "core/apollo/graphql";
import {
  ACCOUNT_ACTION_ACTIVATE,
  ACCOUNT_STATUS_ACTIVE,
  ACCOUNT_STATUS_CREATED,
  ACCOUNT_STATUS_INACTIVE,
  QUERY_PROGRESS_SUCCEED,
  SEALD_ENCRYPTION_BROKEN_ACCESS,
  SEALD_ENCRYPTION_FULL_ACCESS,
  SEALD_ENCRYPTION_NO_ACCESS,
  SEALD_ENCRYPTION_NO_GROUP_CREATED,
  SEALD_ENCRYPTION_PARTIAL_ACCESS,
  SEALD_ENCRYPTION_PARTIAL_ACCESS_WITH_PENDING,
  SEALD_ENCRYPTION_PENDING_ACCESS,
  SEALD_ENCRYPTION_USER_NOT_REGISTERED,
} from "core/consts";
import { getName } from "core/model/accounts";
import { useGetOntology } from "core/model/utils/ontologies/hooks";
import {
  Account,
  AccountRole,
  Account as AccountType,
  Careseeker,
  GetOntologyType,
  Roles,
  SealdEncryptionAccessStatus,
} from "core/types";
import ConfirmationDialog from "ds_legacy/components/ConfirmationDialog";
import InfoCard, { CardContentContainer } from "ds_legacy/components/InfoCard";
import SpeedDial, { SpeedDialAction } from "ds_legacy/components/SpeedDial";
import Tooltip from "ds_legacy/components/Tooltip";
import { ICON_DARK } from "ds_legacy/materials/colors";
import { ACP_CARD_WIDTH, dp, margin, space } from "ds_legacy/materials/metrics";
import { Body, Email, FONT_SIZE_16 } from "ds_legacy/materials/typography";
import { TableHead, TableRow } from "dsl/atoms/Table";
import { useToast } from "dsl/atoms/ToastNotificationContext";
import { Chip, stringToHex } from "dsl/organisms/EventTimeline/Events";
import { CopyIcon, LockIcon, MailIcon, UserPlusIcon } from "lucide-react";
import styled from "styled-components";
import { useTranslations } from "translations";
import Translations from "translations/types";

const SpeedDialPositioner = styled.div`
  position: absolute;
  z-index: 1;
  top: ${dp(-28)};
  right: 0;
`;

export const getCareseekerRoles = (
  roles: Roles | undefined,
  careseekerId: number,
): AccountRole[] => {
  const careseekerRoles =
    roles?.careseeker_roles && roles.careseeker_roles.length > 0
      ? roles.careseeker_roles.find(
          ({ careseeker }) => careseeker?.id === careseekerId || false,
        )
      : undefined;

  return careseekerRoles?.roles?.filter((r) => r && r > 0) || [];
};

export const getCareproviderRoles = (
  roles: Roles | undefined,
  careproviderId: number,
): AccountRole[] => {
  const careproviderRoles =
    roles?.careprovider_roles && roles.careprovider_roles.length > 0
      ? roles.careprovider_roles.find(
          (r) => r?.careprovider?.id === careproviderId || false,
        )
      : undefined;

  return careproviderRoles?.roles?.filter((r: ToType) => r && r > 0) || [];
};

function getRoles(
  roles: Roles | undefined,
  careseekerId: number | undefined,
  careproviderId: number | undefined,
  getOntology: GetOntologyType,
) {
  if (!roles) return [];
  if (careseekerId)
    return getCareseekerRoles(roles, careseekerId).map((id) =>
      getOntology({ type: "accountRoleShort", key: id }),
    );

  if (careproviderId)
    return getCareproviderRoles(roles, careproviderId).map((id) =>
      getOntology({ type: "accountRoleShort", key: id }),
    );

  return [];
}

export function updateRoles({
  careproviderId,
  careseekerId,
  newRoles,
  roles,
}: {
  careproviderId?: number;
  careseekerId?: number;
  newRoles?: AccountRole[];
  roles: Roles;
}): Roles {
  if (careseekerId !== undefined) {
    // Update careseeker roles
    const updatedCareseekerRoles = roles.careseeker_roles?.map((role) => {
      if (role.careseeker?.id === careseekerId) {
        return { ...role, roles: newRoles };
      }
      return role;
    });
    return {
      ...roles,
      careseeker_roles: updatedCareseekerRoles,
    };
  }

  if (careproviderId !== undefined) {
    // Update careprovider roles
    const updatedCareproviderRoles = roles.careprovider_roles?.map((role) => {
      if (role.careprovider?.id === careproviderId) {
        return { ...role, roles: newRoles };
      }
      return role;
    });
    return {
      ...roles,
      careprovider_roles: updatedCareproviderRoles,
    };
  }

  // Update admin roles
  roles.admin_roles = newRoles || [];

  return roles;
}

function Actions({
  accounts,
  getOntology,
  isSSO,
  pageState,
  sealdId,
  setPageState,
  translations,
}: {
  accounts: Readonly<Array<AccountType>>;
  getOntology: GetOntologyType;
  isSSO?: boolean;
  pageState: any;
  sealdId: string | undefined;
  setPageState: (state: any) => void;
  translations: Translations;
}) {
  const actions: SpeedDialAction[] = [];

  if (isSSO) {
    if (sealdId) {
      actions.push({
        icon: <LockIcon style={{ color: ICON_DARK }} size={FONT_SIZE_16} />,
        onClick: () => setPageState({ type: "resetGroup" }),
        testId: "reset-group",
        tooltipTitle: translations.acp.resetGroups.menuResetGroup,
      });
    }
  } else {
    actions.push(
      {
        icon: <UserPlusIcon style={{ color: ICON_DARK }} size={FONT_SIZE_16} />,
        onClick: () => setPageState({ type: "addAccount" }),
        testId: "add_account",
        tooltipTitle: translations.acp.addAccount,
      },
      {
        icon: <CopyIcon style={{ color: ICON_DARK }} size={FONT_SIZE_16} />,
        onClick: () => setPageState({ type: "importAccounts" }),
        testId: "import_accounts",
        tooltipTitle: translations.acp.excelImportAccounts,
      },
    );

    if (sealdId) {
      actions.push({
        icon: <LockIcon style={{ color: ICON_DARK }} size={FONT_SIZE_16} />,
        onClick: () => setPageState({ type: "resetGroup" }),
        testId: "reset-group",
        tooltipTitle: translations.acp.resetGroups.menuResetGroup,
      });
    }

    const createdAccounts = accounts?.filter(
      (a) => a.status == ACCOUNT_STATUS_CREATED,
    );

    if (createdAccounts && createdAccounts.length > 0) {
      actions.push({
        icon: <MailIcon />,
        onClick: () => setPageState({ type: "batchActivate" }),
        testId: "activate-accounts",
        tooltipTitle: translations.acp.batchSendActivation,
      });
    }
  }

  return (
    <>
      <SpeedDialPositioner>
        <SpeedDial
          tooltip="Account Actions"
          actions={actions}
          testId="account_actions"
        />
      </SpeedDialPositioner>
      {pageState && pageState.type === "batchActivate" && (
        <BatchActivate
          getOntology={getOntology}
          setPageState={setPageState}
          accounts={accounts.filter((a) => a.status == ACCOUNT_STATUS_CREATED)}
          translations={translations}
        />
      )}
      {pageState && pageState.type === "resetGroup" && (
        <GroupResetDialog sealdId={sealdId} setPageState={setPageState} />
      )}
    </>
  );
}

function GroupResetDialog({
  sealdId,
  setPageState,
}: {
  sealdId: string | undefined;
  setPageState: (state: any) => void;
}) {
  const toast = useToast();
  const translations = useTranslations();

  const close = () => setPageState({});

  const [mutate, queryProgress] = useSealdGroupReset({
    onCompleted() {
      close();
      toast({
        message: translations.acp.resetGroups.successMessage,
        color: "success",
      });
    },
    onError: (error: ApolloError) => {
      toast({ message: error.message, color: "danger" });
    },
  });

  const [progress, data, { error }] = useSealdGroupSessionsCheck({
    sealdId,
  });

  return (
    <ConfirmationDialog
      opened
      title={translations.acp.resetGroups.resetGroupsTitle}
      nodeContent={
        data ? (
          <>
            {translations.acp.resetGroups.resetGroupsSubtitle({
              sessionNumber:
                data.sealdGroupSessions.pending_seald_access_count.toString(),
            })}
            <TableContainer component={Paper} style={{ maxWidth: 600 }}>
              <Table aria-label="sessions-lost" sx={{ overflowX: "auto" }}>
                <TableHead>
                  <TableCell component="th" scope="row">
                    {translations.acp.resetGroups.columnLostSession}
                  </TableCell>
                  <TableCell align="center">
                    {translations.acp.resetGroups.columnCount}
                  </TableCell>
                </TableHead>
                <TableBody>
                  <TableRow key="patients">
                    <TableCell component="th" scope="row">
                      {translations.acp.resetGroups.activePatients}
                    </TableCell>
                    <TableCell align="center">
                      {data.sealdGroupSessions.lost_sessions.active_patients}
                    </TableCell>
                  </TableRow>
                  <TableRow key="files">
                    <TableCell component="th" scope="row">
                      {translations.acp.resetGroups.activeFiles}
                    </TableCell>
                    <TableCell align="center">
                      {data.sealdGroupSessions.lost_sessions.active_files}
                    </TableCell>
                  </TableRow>
                  <TableRow key="requests">
                    <TableCell component="th" scope="row">
                      {translations.acp.resetGroups.activeRequests}
                    </TableCell>
                    <TableCell align="center">
                      {data.sealdGroupSessions.lost_sessions.active_requests}
                    </TableCell>
                  </TableRow>
                  <TableRow key="archived">
                    <TableCell component="th" scope="row">
                      {translations.acp.resetGroups.archived}
                    </TableCell>
                    <TableCell align="center">
                      {data.sealdGroupSessions.lost_sessions.archived}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <>
            <p>{error?.message}</p>
          </>
        )
      }
      queryProgress={composeProgress([queryProgress, progress])}
      onSubmit={() => mutate({ seald_id: sealdId })}
      confirmButtonProps={{
        disabled: !data || progress !== QUERY_PROGRESS_SUCCEED,
      }}
      onCancel={() => close()}
    />
  );
}

function BatchActivate({
  accounts,
  getOntology,
  setPageState,
  translations,
}: {
  accounts: Array<Account>;
  getOntology: GetOntologyType;
  setPageState: (state: any) => void;
  translations: Translations;
}) {
  const client = useApolloClient();
  const created = accounts.filter((a) => a.status == ACCOUNT_STATUS_CREATED);
  const close = () => setPageState({});
  const toast = useToast();

  const resendActivation = (accountId: number) =>
    client?.mutate({
      mutation: UPDATE_ACCOUNT,
      variables: {
        id: accountId,
        input: {
          action: {
            type: ACCOUNT_ACTION_ACTIVATE,
            version: "2",
          },
        },
      },
    });

  return (
    <ConfirmationDialog
      id="patient_success_dialog"
      opened
      onSubmit={() =>
        Promise.all(created.map((account) => resendActivation(account.id)))
          .then(() => {
            toast({
              color: "success",
              message: translations.acp.notifyActivationSent,
            });
            close();
          })
          .catch(function (err) {
            toast({
              message: JSON.stringify(err),
              color: "primary",
            });
          })
      }
      onCancel={close}
      title={translations.acp.batchSendActivation}
      componentContent={
        <div>
          {created.map((c) => (
            <DialogContentText key={c.id}>
              {getName(c, getOntology)}
            </DialogContentText>
          ))}
        </div>
      }
      submitText="OK"
    />
  );
}

const statusPrioMap: Map<number, number> = new Map([
  [ACCOUNT_STATUS_ACTIVE, 1],
  [ACCOUNT_STATUS_CREATED, 2],
  [ACCOUNT_STATUS_INACTIVE, 3],
]);

const ENCRYPTION_STATUS_CONFIG: {
  [encryptionStatus in SealdEncryptionAccessStatus]: {
    color: string;
    label: string;
    tooltip: string;
  };
} = {
  [SEALD_ENCRYPTION_NO_ACCESS]: {
    color: "gray",
    label: "no access",
    tooltip: "not in group yet plus no pending access",
  },
  [SEALD_ENCRYPTION_FULL_ACCESS]: {
    color: "green",
    label: "full access",
    tooltip: "has access to view and create encrypted data",
  },
  [SEALD_ENCRYPTION_PENDING_ACCESS]: {
    color: "orange",
    label: "pending access",
    tooltip:
      "no access yet. one of the accounts with access in green needs to open the app",
  },
  [SEALD_ENCRYPTION_BROKEN_ACCESS]: {
    color: "red",
    label: "broken access",
    tooltip:
      "something went wrong in the access creation. please check with engineering.",
  },
  [SEALD_ENCRYPTION_USER_NOT_REGISTERED]: {
    color: "gray",
    label: "not registered",
    tooltip: "user is not registered, which should happen by login in",
  },
  [SEALD_ENCRYPTION_NO_GROUP_CREATED]: {
    color: "gray",
    label: "no group",
    tooltip:
      "No group has been created yet. Likely no one logged in yet or they are having Seald connection issues.",
  },
  [SEALD_ENCRYPTION_PARTIAL_ACCESS]: {
    color: "red",
    label: "partial access without key",
    tooltip:
      "user only has access to the sessions they created. there isn't a pending group access. this should be fixed over night",
  },
  [SEALD_ENCRYPTION_PARTIAL_ACCESS_WITH_PENDING]: {
    color: "orange",
    label: "partial access",
    tooltip:
      "user only has access to the sessions they created. there is a pending group access. one of the accounts with access in green needs to open the app",
  },
};

function EncryptionAccessStatusChip({
  encryptionStatus,
}: {
  encryptionStatus: Account["seald_encryption_status"];
}) {
  if (encryptionStatus == null) return null;

  const { color, label, tooltip } = ENCRYPTION_STATUS_CONFIG[encryptionStatus];

  return (
    <Tooltip title={tooltip} placement="left">
      <div
        style={{
          display: "flex",
          flexFlow: "wrap",
          minHeight: space(7),
          alignItems: "center",
        }}
      >
        <Chip
          key={encryptionStatus}
          color={color}
          label={label}
          chipMargin="0"
        />
      </div>
    </Tooltip>
  );
}

export function AccountsTable({
  accounts,
  careproviderId,
  careseekerId,
  goToAccount,
  isSSO,
  pageState,
  sealdId,
  setPageState,
  title,
  withAddAccount = true,
}: {
  accounts: Readonly<Account[]>;
  careproviderId?: number;
  careseeker?: Careseeker;
  careseekerId?: number;
  goToAccount: (account: number, page?: string) => void;
  isSSO?: boolean;
  pageState: { accountId?: number; type?: string };
  sealdId: string | undefined;
  setPageState: (state: { accountId?: number; type?: string }) => void;
  title: string;
  withAddAccount?: boolean;
}) {
  const translations = useTranslations();
  const getOntology = useGetOntology();

  return (
    <InfoCard
      themed="light"
      position="relative"
      title={title}
      width={ACP_CARD_WIDTH}
    >
      <CardContentContainer overflowX="auto" noPadding>
        <Table sx={{ overflowX: "auto" }}>
          <TableHead>
            <TableCell>#</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Roles</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Encryption Access</TableCell>
          </TableHead>
          {accounts && (
            <TableBody>
              {accounts
                .clone()
                .sort((x, y) => {
                  if (x.status != y.status) {
                    return (
                      (statusPrioMap.get(x.status || 0) || 0) -
                      (statusPrioMap.get(y.status || 0) || 0)
                    );
                  }
                  return (x.last_name || "").localeCompare(y.last_name || "");
                })
                .map((a) => {
                  return (
                    <TableRow
                      style={{
                        maxWidth: ACP_CARD_WIDTH,
                        opacity: a.status == ACCOUNT_STATUS_INACTIVE ? 0.6 : 1,
                      }}
                      key={a.id}
                      onClick={() => goToAccount(a.id)}
                    >
                      <TableCell>
                        <Body margin={margin(0)}>{`#${a.id || ""}`}</Body>
                      </TableCell>
                      <TableCell style={{ maxWidth: dp(145) }}>
                        <Body margin={margin(0)} maxWidth={dp(145)} breakWord>
                          {getName(a, getOntology, {
                            withSalutation: true,
                            withAcademicTitle: true,
                          })}
                        </Body>
                        {a.email && (
                          <Email fontWeight={300} margin={margin(0)} textBreak>
                            {a.email}
                          </Email>
                        )}
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            flexFlow: "wrap",
                            width: space(21),
                            minHeight: space(7),
                            alignItems: "center",
                          }}
                        >
                          {getRoles(
                            a.roles,
                            careseekerId,
                            careproviderId,
                            getOntology,
                          )
                            .sort()
                            .map((name) => (
                              <Chip
                                key={name}
                                color={stringToHex(name)}
                                label={name}
                                chipMargin={dp(1)}
                              />
                            ))}
                        </div>
                      </TableCell>
                      <TableCell>
                        <Body margin={margin(0)}>
                          {getOntology({
                            type: "accountStatus",
                            key: a.status,
                          })}
                        </Body>
                      </TableCell>
                      <TableCell style={{ paddingRight: 0 }}>
                        <EncryptionAccessStatusChip
                          encryptionStatus={a.seald_encryption_status}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          )}
        </Table>
        {withAddAccount && (
          <Actions
            getOntology={getOntology}
            pageState={pageState}
            setPageState={setPageState}
            translations={translations}
            accounts={accounts}
            sealdId={sealdId}
            isSSO={isSSO}
          />
        )}
      </CardContentContainer>
    </InfoCard>
  );
}
