import {
  QUERY_PROGRESS_FAILED,
  QUERY_PROGRESS_PENDING,
  QUERY_PROGRESS_SUCCEED,
  TRACK_EVENTS,
} from "core/consts";
import { SEALD_TEST_URL_API, SEALD_TEST_URL_SSKS } from "core/seald";
import { QueryProgress } from "core/types";
import { useEffect, useState } from "react";
import { useTracking } from "react-tracking";

export function useSealdProgress() {
  const { trackEvent } = useTracking();
  const [sealdProgress, setSealProgress] = useState<QueryProgress>(
    QUERY_PROGRESS_PENDING,
  );

  const checkSealdProgress = async () => {
    try {
      await Promise.all([
        fetch(SEALD_TEST_URL_SSKS),
        fetch(SEALD_TEST_URL_API),
      ]);
      setSealProgress(QUERY_PROGRESS_SUCCEED);
    } catch (_) {
      setSealProgress(QUERY_PROGRESS_FAILED);
      trackEvent({ name: TRACK_EVENTS.SEALD_CONNECTION_BANNER });
    }
  };

  useEffect(() => {
    checkSealdProgress();
  }, []);

  return { sealdProgress, checkSealdProgress };
}
