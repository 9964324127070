import { AnyObject } from "core/types";
import React, { ReactElement } from "react";
import { NEVER, Observable, Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import {
  ObservableValue,
  useFormElementContext,
  useStateDispatcherContext,
} from "./Context";
import {
  INITIAL_CHANGE_EVENT_TYPE,
  VALIDATION_FAILED_EVENT_TYPE,
} from "./FormEvents";
import { getValue } from "./StateValueHelpers";

// types
type FormWatcherCompProps = {
  children: (props: {
    validation: any;
    value: FormWatcherState["value"];
    watchedStatePath: string;
    watchedValidation: any;
    watchedValue: any;
  }) => ReactElement | null;
  debug?: boolean;
  watchPath: string | ((statePath: string) => string);
};

type FormWatcherProps = FormWatcherCompProps & {
  formElementContext: ReturnType<typeof useFormElementContext>;
  stateDispatcherContext: ReturnType<typeof useStateDispatcherContext>;
};

type FormWatcherState = AnyObject;

const NeverObservable = NEVER;

const formWatcherTypeError = new Error(
  "children of FormWatcher must be a function of type (stateValue, props) => React$element",
);

function getAssignedObs({ stateDispatcherContext }: FormWatcherProps) {
  if (stateDispatcherContext.rootValueChangeObs != null)
    return stateDispatcherContext.rootValueChangeObs;
  return NeverObservable;
}

function getWatchedPath({ formElementContext, watchPath }: FormWatcherProps) {
  return typeof watchPath === "function"
    ? watchPath(formElementContext.completeStatePath || "")
    : watchPath;
}

class FormWatcher extends React.Component<FormWatcherProps, FormWatcherState> {
  watchPath: string;
  valueChangeObs: Observable<ObservableValue>;
  subscription?: Subscription;

  constructor(props: FormWatcherProps) {
    super(props);

    this.state = {
      value: null,
      validation: true,
    };

    this.watchPath = getWatchedPath(props);

    if (this.watchPath == null) {
      throw new Error(
        "watchPath props is not set on FormWatcher, you have to set a statepath string on which the FormWatcher will watch",
      );
    }
    this.valueChangeObs = this.selectObs(props);
  }

  selectObs(props: FormWatcherProps) {
    return getAssignedObs(props).pipe(
      filter((e) => {
        if (props.debug) {
          console.log("FILTERING", e, "In watcher", this.watchPath);
        }

        if (e.statePath)
          return (
            this.watchPath.startsWith(e.statePath) ||
            e.statePath.startsWith(this.watchPath)
          );
        else if (e.type === INITIAL_CHANGE_EVENT_TYPE) return true;
        else if (e.type === VALIDATION_FAILED_EVENT_TYPE) return true;
        return false;
      }),
    );
  }

  connectsToFormController() {
    if (this.subscription) this.subscription.unsubscribe();
    this.subscription = this.valueChangeObs.subscribe((e) => {
      this.setState({
        value: e.value,
        validation: e.validation,
      });
    });
  }

  componentDidMount() {
    this.connectsToFormController();
  }

  UNSAFE_componentWillReceiveProps(nextProps: FormWatcherProps) {
    if (this.valueChangeObs !== getAssignedObs(nextProps)) {
      this.valueChangeObs = this.selectObs(nextProps);
      this.connectsToFormController();
    }
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  render() {
    if (typeof this.props.children !== "function") throw formWatcherTypeError;
    const watchedValidation =
      typeof this.state.validation === "object" &&
      this.state.validation !== null
        ? this.state.validation.getNestedValidation(this.watchPath)
        : this.state.validation;

    return this.props.children({
      value: this.state.value,
      watchedValue: getValue(this.state.value, getWatchedPath(this.props)),
      watchedStatePath: this.watchPath,
      validation: this.state.validation,
      watchedValidation,
    });
  }
}

export default function FormWatcherComp(props: FormWatcherCompProps) {
  const stateDispatcherContext = useStateDispatcherContext();
  const formElementContext = useFormElementContext();
  return (
    <FormWatcher
      stateDispatcherContext={stateDispatcherContext}
      formElementContext={formElementContext}
      {...props}
    />
  );
}
