import { VALIDATION_INVALID, VALIDATION_MISSING } from "core/consts";
import { isCareproviderAddress } from "core/model/careproviders";
import { Address, AnyObject, CareproviderAddress, Location } from "core/types";
import Translations from "translations/types";

const earthRadius = 6371000;

export type Coordinates = {
  latitude: number;
  longitude: number;
};

type Point = {
  x: number;
  y: number;
};

export function formatDistance(d: number) {
  if (d == 0) return `0 m`;
  if (d < 100) return "<100 m";
  if (d > 10000) return `${Math.floor(d / 1000)} km`;
  if (d > 1000) return `${Math.floor(d / 100) / 10} km`;
  if (d < 100) return "100 m";
  return `${100 * Math.floor(d / 100)} m`;
}

const toRadians = (degree: number) => (degree * Math.PI) / 180;

const toDegrees = (radian: number) => (radian * 180) / Math.PI;

const pointToRadians = (point: Point) => ({
  x: toRadians(point.x),
  y: toRadians(point.y),
});

const pointToDegrees = (point: Point) => ({
  x: toDegrees(point.x),
  y: toDegrees(point.y),
});

export const getRandomCoordinates = (
  coordinates: Coordinates,
  radius: number,
) => {
  const point = { x: coordinates.latitude, y: coordinates.longitude };

  const coords = pointToRadians(point);

  const u = Math.random();

  let delta = Math.sqrt(u) * radius;
  delta /= earthRadius;

  const theta = Math.random() * 2 * Math.PI;

  const sinX = Math.sin(coords.x);
  const cosX = Math.cos(coords.x);

  const sinDelta = Math.sin(delta);
  const cosDelta = Math.cos(delta);
  const sinTheta = Math.sin(theta);
  const cosTheta = Math.cos(theta);

  const sinPhi = sinX * cosDelta + cosX * sinDelta * cosTheta;
  const phi = Math.asin(sinPhi);

  let y = sinTheta * sinDelta * cosX;
  y = coords.y + Math.atan2(y, cosDelta - sinX * sinPhi);

  // normalize
  y = ((y + 3 * Math.PI) % (2 * Math.PI)) - Math.PI;

  const newPoint = pointToDegrees({
    x: phi,
    y,
  });

  return { latitude: newPoint.x, longitude: newPoint.y };
};

export function distance(orig: Coordinates, dest: Coordinates) {
  const R = 6371; // Radius of the earth in km
  const dLat = toRadians(dest.latitude - orig.latitude); // deg2rad below
  const dLon = toRadians(dest.longitude - orig.longitude);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(orig.latitude)) *
      Math.cos(toRadians(dest.latitude)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
}

export function formatLocation({
  location,
  showFloor,
  startWithZipcode,
  translations,
}: {
  location: (Address & Location) | undefined;
  showFloor?: boolean;
  startWithZipcode?: boolean;
  translations: Translations;
}) {
  if (!location) return "";

  const hasHouseNumber = !!(
    location?.encrypted_house_number || location?.street_number
  );
  const houseNumber =
    location?.encrypted_house_number?.decrypted || location?.street_number;
  let address = "";

  if (hasHouseNumber) {
    if (location?.zipcode) {
      address += location.zipcode;
    }
    if (location?.city) {
      address += ` ${location.city}, `;
    }
    if (location?.street && houseNumber) {
      address += `${location.street} ${houseNumber}`;
    } else if (location?.street) {
      address += `${location.street}`;
    }
    if (location?.floor?.length && showFloor) {
      address += ` ${translations.patient.patientInformation.floor} ${location.floor}`;
    }
  } else if (startWithZipcode) {
    if (location?.zipcode) {
      address += location.zipcode;
      if (location?.city) {
        address += location?.street
          ? ` ${location.city}, ${location.street}`
          : ` ${location.city}`;
      }
    }
  } else {
    if (location?.zipcode && location?.street) {
      address += `${location.street}, `;
    }
    if (location?.zipcode) {
      address += location.zipcode;
    }
    if (location?.city) {
      address += ` ${location.city}`;
    }
  }
  return address === "" ? null : address;
}

// address format example: Street 10, 10439 Berlin
export const formatAddress = (providerAddress: CareproviderAddress): string => {
  const { address, city, zip_code } = providerAddress || {};
  const components = [
    address,
    zip_code && address ? `, ${zip_code}` : zip_code,
    (zip_code || address) && city ? ` ${city}` : city,
  ].truthy();
  const addressString = components.join("");
  return addressString;
};

const testZipcode = (
  code: string | null | undefined,
  isRequired: boolean | null | undefined,
) => {
  const zipcodeRegex = /^[0-9]{5}$/g;

  if (!isRequired) {
    if (code && !code.match(zipcodeRegex)) return VALIDATION_INVALID;
    return true;
  }

  if (!code) return VALIDATION_MISSING;
  if (!code.match(zipcodeRegex)) return VALIDATION_INVALID;
  return true;
};

export const preprocessZipcode = (value?: string | null | undefined) =>
  value ? value.trim() : value;

export const validateZipcode =
  (isRequired?: boolean) => (value: AnyObject | string | null | undefined) => {
    if (!value && !isRequired) return true;

    if (typeof value === "string")
      return testZipcode(preprocessZipcode(value), isRequired);

    if (value != null) {
      const zipcode = value.get("zipcode");
      return testZipcode(preprocessZipcode(zipcode), isRequired);
    }
    return VALIDATION_MISSING;
  };

export const findCenterOfTwoPoints = (
  location: {
    lat: number;
    lng: number;
  },
  destination?: {
    lat: number;
    lng: number;
  },
) => {
  if (!destination) return location;

  return {
    lat: (location.lat + destination.lat) / 2,
    lng: (location.lng + destination.lng) / 2,
  };
};

export const convertAddressToLocation = (
  address: Address | CareproviderAddress | undefined,
): Location | undefined => {
  if (!address) return undefined;

  const streetAddress = address.address;
  const splitStreetAndHouseNumber = streetAddress
    ?.split(/(\d+)/)
    .filter(Boolean);
  const houseNumber =
    address?.street_number ??
    splitStreetAndHouseNumber?.find((s) => parseInt(s) > 0);
  const formattedStreet = splitStreetAndHouseNumber
    ?.find((s) => isNaN(parseInt(s)))
    ?.replace(/,/g, "")
    ?.trim();

  return {
    city: address.city,
    zipcode: isCareproviderAddress(address)
      ? address.zip_code
      : address.zipcode,
    country: address.country,
    latitude: address.latitude,
    longitude: address.longitude,
    street: address.street ?? formattedStreet,
    radius_in_meter: 500,
    encrypted_house_number: {
      decrypted: houseNumber,
      iv: "",
      content: "",
      seald_content: "",
    },
  };
};

export const convertMetertoKm = (distanceInMeters: number) =>
  Math.ceil(distanceInMeters / 1000);
