import { isLoading } from "apollo/utils";
import { useEnvContext } from "context/EnvContext";
import { APP_ACP, REDIRECT_PARAM } from "core/consts";
import { isAdminLogged } from "core/model/accounts";
import { activateEnvSwitch } from "core/model/utils/featureFlags";
import { getQueryVariable } from "core/model/utils/urls";
import { useManualSetLegalDocuments } from "dsl/atoms/LegalDocuments";
import { useHandleLogin } from "dsl/ecosystems/CareproviderOnboardingLoginPage/useHandleLogin";
import { LoginPageV2 } from "dsl/ecosystems/LoginPageV2";
import {
  loginPageModelDefinition,
  validateChallenge,
  validateLogin,
} from "dsl/ecosystems/LoginPageV2/formConfig";
import { useAcpHandlerUrls } from "dsl/hooks/useNavigationHandlers";
import { acpAuthPath, defaultRedirectPath } from "entries/acp/Router";
import { ReactNode } from "react";
import { SimpleFormRenderProps } from "react-forms-state/src";
import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "reduxentities/hooks";

export default function AcpAuthPage() {
  const logged = useAppSelector(isAdminLogged);
  const location = useLocation();
  const redirectTo = getQueryVariable(location.search, REDIRECT_PARAM);
  const { env } = useEnvContext();
  const {
    challengeTimestamp,
    handleLogin,
    isLogin,
    loginErrors,
    loginView,
    progressLogin,
    resetError,
    setLoginView,
    setProgressLogin,
  } = useHandleLogin();
  useManualSetLegalDocuments();
  const { goToForgotPassword: goToForgotPasswordUrl, goToProductionEnv } =
    useAcpHandlerUrls();

  return logged === "ok" ? (
    <Navigate to={redirectTo ?? defaultRedirectPath} replace />
  ) : (
    <main>
      <SimpleFormRenderProps
        asHtmlForm
        onSubmit={(props) => handleLogin(props, setProgressLogin)}
        modelDefinition={loginPageModelDefinition}
        validate={isLogin ? validateLogin : validateChallenge}
      >
        {({ onChange, submit }) => (
          <LoginPageV2
            app={APP_ACP}
            challengeTimestamp={challengeTimestamp}
            env={env}
            goToForgotPassword={goToForgotPasswordUrl()}
            goToProductionUrl={goToProductionEnv()}
            handleLogin={(props) => handleLogin(props, setProgressLogin)}
            isLoading={isLoading(progressLogin)}
            loginErrors={loginErrors}
            loginView={loginView}
            onBackToLogin={() => {
              onChange(null, "challenge", true);
              onChange(null, "email", true);
              onChange(null, "password", true);
              resetError();
              setLoginView("login");
            }}
            onChangeFormValues={onChange}
            onSubmit={submit}
            resetError={resetError}
            withDevTools={activateEnvSwitch}
          />
        )}
      </SimpleFormRenderProps>
    </main>
  );
}

export function AcpAuthenticate({ children }: { children: ReactNode | null }) {
  const logged = useAppSelector(isAdminLogged);
  const { pathname, search } = useLocation();
  const redirectTo = encodeURIComponent(`${pathname}${search}`);

  return logged === "ok" ? (
    <>{children}</>
  ) : (
    <Navigate
      to={{ pathname: acpAuthPath, search: `${REDIRECT_PARAM}=${redirectTo}` }}
      replace
    />
  );
}
