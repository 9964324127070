import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getFirstProviderRole } from "core/model/careproviders";
import {
  Account,
  AccountInIdentification,
  Careprovider,
  CareproviderRoles,
  Careseeker,
  CareseekerRoles,
  Country,
} from "core/types";
import { getUnixTime, subDays } from "date-fns";
import {
  findMatchingCareproviderFromState,
  findMatchingCareseekerFromState,
  getFirstSeekerRole,
  setAuthState,
  setCareprovider,
  setCareseeker,
  setCareseekerRoles,
  setCountry,
  setProviderSearchCareseeker,
} from "../utils/authUtils";

export type StateIdentification = {
  account: AccountInIdentification;
  admin_roles: number[];
  // active_roles #TODO add correct typing of active_roles
  careprovider_roles: CareproviderRoles[];
  careseeker_roles: CareseekerRoles[];
  privateKey: string;
};

export type AuthState = {
  active_roles?: number[];
  careprovider?: number;
  careprovider_name?: string;
  careseeker?: number;
  careseeker_name?: string;
  country?: Country;
  credentials?: {
    auth_type?: "password" | "sso";
    expiration?: number;
    token?: string;
    token_type?: string;
  };
  identification?: StateIdentification;
  version?: string;
};

const yesterday = getUnixTime(subDays(new Date(), 1));

const initialState: AuthState = {
  credentials: { token: undefined },
  identification: undefined,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    TOKEN_CHANGED(state, action: PayloadAction<AnyObject, string>) {
      let updatedState = setAuthState(state, action);

      const existingCareprovider = findMatchingCareproviderFromState(
        action?.payload?.identification?.careprovider_roles,
        state,
      );
      const firstProviderInRoles = getFirstProviderRole(
        action.payload.identification.careprovider_roles,
      );
      const careproviderFromRolePayload = firstProviderInRoles?.careprovider;

      const careproviderId =
        action.payload.careproviderToLog ||
        existingCareprovider ||
        careproviderFromRolePayload?.id;

      const careprovider: Careprovider | undefined =
        action?.payload?.identification?.careprovider_roles?.find(
          (role: CareproviderRoles) => role?.id === careproviderId,
        )?.careprovider;

      updatedState = setCareprovider({
        careproviderId,
        careproviderName: careprovider?.name,
        state: updatedState,
      });

      const existingCareseeker = findMatchingCareseekerFromState(
        action?.payload?.identification?.careseeker_roles,
        state,
      );

      const firstSeekerInRoles = getFirstSeekerRole(
        action.payload.identification.careseeker_roles,
      )?.careseeker;
      const careseekerId = existingCareseeker || firstSeekerInRoles?.id;

      const careseeker: Careseeker | undefined =
        action.payload.identification.careseeker_roles?.find(
          (role: CareproviderRoles) => role?.id && role?.id === careseekerId,
        )?.careseeker;

      updatedState = setCareseeker(
        careseekerId,
        careseeker?.name ?? "",
        updatedState,
      );

      return setCountry(
        careseeker?.address?.country ?? careprovider?.address?.country,
        updatedState,
      );
    },

    CARESEEKER_CHANGED(
      state,
      action: PayloadAction<
        {
          careseeker_id: number | undefined;
          careseeker_name: string | undefined;
          country: string | undefined;
        },
        string
      >,
    ) {
      const updatedState = setCountry(action.payload?.country, state);
      return setCareseeker(
        action.payload.careseeker_id,
        action.payload?.careseeker_name,
        updatedState,
      );
    },

    CAREPROVIDER_CHANGED(
      state,
      action: PayloadAction<
        { careproviderId: number; country?: string },
        string
      >,
    ) {
      const updatedState = setCountry(action.payload?.country, state);
      return setCareprovider({
        careproviderId: action.payload.careproviderId,
        state: updatedState,
      });
    },

    SET_CARESEEKER(
      state,
      action: PayloadAction<
        {
          identification: {
            account: Account;
            careseeker_roles: CareseekerRoles[] | undefined;
          };
        },
        string
      >,
    ) {
      const updatedRoles = setCareseekerRoles(state, action);
      return setProviderSearchCareseeker(updatedRoles, action);
    },

    COUNTRY_CHANGED(
      state,
      action: PayloadAction<{ country: string | undefined }, string>,
    ) {
      return setCountry(action.payload?.country, state);
    },

    LOGGED_OUT(state) {
      return {
        ...state,
        careprovider: undefined,
        careprovider_name: undefined,
        careseeker: undefined,
        careseeker_name: undefined,
        credentials: { token: undefined },
        active_roles: undefined,
        identification: undefined,
      };
    },

    _TEST_EXPIRE_SESSION(state) {
      return {
        ...state,
        credentials: {
          token: state.credentials?.token,
          expiration: yesterday,
        },
      };
    },
  },
});

export const {
  _TEST_EXPIRE_SESSION,
  CAREPROVIDER_CHANGED,
  CARESEEKER_CHANGED,
  COUNTRY_CHANGED,
  LOGGED_OUT,
  SET_CARESEEKER,
  TOKEN_CHANGED,
} = authSlice.actions;

export default authSlice.reducer;
