import { useEnvContext } from "context/EnvContext";
import { APP_PROVIDER_SEARCH, TRACK_EVENTS } from "core/consts";
import { IsSealdOnly } from "core/types";
import { createContext, useCallback, useContext } from "react";
import { useTracking } from "react-tracking";
import {
  useLoggedCareproviderConfig,
  useLoggedCareseekerConfig,
} from "reduxentities/hooks";

export const SealdOnlyContext = createContext<{
  isSealdOnly: IsSealdOnly;
}>({
  isSealdOnly: () => false,
});

export const useIsSealdOnly = () => useContext(SealdOnlyContext);

export default function SealdOnlyContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { app } = useEnvContext();
  const { trackEvent } = useTracking();

  const loggedCareseekerWithSealdOnlyEncryption = useLoggedCareseekerConfig(
    "seald_only_encryption",
  );

  const loggedCareproviderWithSealdOnlyEncryption = useLoggedCareproviderConfig(
    "seald_only_encryption",
  );

  const isSealdOnly: IsSealdOnly = useCallback(
    (sessionsInContext) => {
      if (
        Boolean(sessionsInContext?.oldSession) &&
        !sessionsInContext?.newSealdSession
      ) {
        // when there are already sessions in context (i.e. we're not creating a new session)
        // if only the old encryption exists, still allow the user to use it
        // this is to prevent users recently fixed to not loose encrypted data access
        //  we should delete this soon [now=Jun2024]
        trackEvent({ name: TRACK_EVENTS.SEALD_ONLY_FALLBACK_TRIGGERED });
        return false;
      }

      return Boolean(
        app === APP_PROVIDER_SEARCH ||
          loggedCareseekerWithSealdOnlyEncryption ||
          loggedCareproviderWithSealdOnlyEncryption,
      );
    },
    [
      trackEvent,
      app,
      loggedCareseekerWithSealdOnlyEncryption,
      loggedCareproviderWithSealdOnlyEncryption,
    ],
  );

  return (
    <SealdOnlyContext.Provider value={{ isSealdOnly }}>
      {children}
    </SealdOnlyContext.Provider>
  );
}
