import {
  ApolloError,
  DocumentNode,
  MutationHookOptions,
  OperationVariables,
  useApolloClient,
  useMutation,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { useErrorHandler } from "apollo/mutations/utils";
import { logError } from "apollo/utils";
import {
  QUERY_PROGRESS_FAILED,
  QUERY_PROGRESS_NOT_STARTED,
  QUERY_PROGRESS_PENDING,
  QUERY_PROGRESS_SUCCEED,
} from "core/consts";
import { useSafeState } from "core/hooks";
import { getError } from "core/model/utils/errors";
import { AnyObject } from "core/types";
import { usePreventUnload } from "dsl/hooks";
import { useCallback, useMemo } from "react";

type ExtractInput<T> = T extends { input: infer U } ? U : never;
type OmitInput<T> = T extends { input: any } ? Omit<T, "input"> : T;

export function getMutationProgress(
  loading: boolean,
  error: ApolloError | undefined,
  started: boolean,
) {
  if (error != null) {
    logError(error);
    return QUERY_PROGRESS_FAILED;
  }
  if (loading) return QUERY_PROGRESS_PENDING;
  return started ? QUERY_PROGRESS_SUCCEED : QUERY_PROGRESS_NOT_STARTED;
}

export function useGenericProgress<TData, TVariables>(
  mutate: ReturnType<typeof useMutation<TData, TVariables>>[0],
  { error, loading }: { error: ApolloError | undefined; loading: boolean },
) {
  const [started, setStarted] = useSafeState(false);

  const statefulMutation = useCallback(
    (
      options?: Parameters<
        ReturnType<typeof useMutation<TData, TVariables>>[0]
      >[0],
    ) => {
      setStarted(true);
      return mutate(options);
    },
    [mutate],
  );

  const queryProgress = useMemo(
    () => getMutationProgress(loading, error, started),
    [loading, started, error],
  );

  const resetProgress = useCallback(() => setStarted(false), [setStarted]);

  return [statefulMutation, queryProgress, resetProgress] as const;
}

export function useGenericMutation<TData, TVariables = OperationVariables>(
  gqlMutation: DocumentNode,
  options?: MutationHookOptions<NoInfer<TData>, NoInfer<OmitInput<TVariables>>>,
) {
  const [mutate, { data, error, loading }] = useMutation<
    TData,
    OmitInput<TVariables>
  >(gqlMutation, options);

  const [resettableMutation, progress, reset] = useGenericProgress<
    TData,
    OmitInput<TVariables>
  >(mutate, { loading, error });

  usePreventUnload(progress);

  const mutation = useCallback(
    (
      input?: ExtractInput<TVariables>,
      extraVariables?: OmitInput<TVariables>,
    ) =>
      resettableMutation({
        variables: { input, ...extraVariables } as OmitInput<TVariables> & {
          input: ExtractInput<TVariables>;
        },
      }),
    [resettableMutation],
  );

  return [mutation, progress, reset, data, error] as const;
}

export const useRefetchOnConflict = () => {
  const errorHandler = useErrorHandler(onError);
  const client = useApolloClient();
  return useCallback(
    ({
      context,
      error,
      query,
      variables,
    }: {
      context?: AnyObject;
      error: unknown;
      query: DocumentNode;
      variables: AnyObject;
    }) => {
      const err = getError(error);
      if (err.message.includes("409")) {
        return client.query({
          query,
          variables,
          context,
          fetchPolicy: "network-only",
        });
      }
      return errorHandler(err);
    },
    [errorHandler, client],
  );
};
