import { MutableRefObject, ReactNode, useEffect, useMemo, useRef } from "react";
import { Observable } from "rxjs";
import { FormRendererContext, ObservableValue } from "./Context";
import { FormContainerWithTranslations } from "./FormContainer";
import { convertIn, convertOut, validateModel } from "./FormModel";
import { StateDispatcherRenderProps } from "./StateDispatcher";
import { ConversionJobs, WithFormProps, _FormProps } from "./types";

export type FormRendererContextProps = {
  dirty: boolean;
  onChange: (value: any, statePath: string, validation: any) => void;
  resetValidation: (paths?: ObservableValue["paths"]) => void;
  submit: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  valueChangeObs: Observable<ObservableValue>;
};

export type WithChildren = {
  children: (props: FormRendererContextProps) => ReactNode;
};

type WithModelDefinition = {
  modelDefinition: ConversionJobs;
};

type FormRenderPropsProps<Props> = Props & WithChildren & WithFormProps;

type SimpleFormRenderPropsProps<Props> = Props &
  WithChildren &
  WithFormProps &
  WithModelDefinition;

export function SimpleForm({
  modelDefinition,
  resetAfterSubmit,
}: WithModelDefinition & Pick<WithFormProps, "resetAfterSubmit">) {
  return Form({
    modelDefinition,
    convertOut: convertOut(modelDefinition),
    convertIn: convertIn(modelDefinition),
    validate: validateModel(modelDefinition),
    resetAfterSubmit,
  });
}

export const handleInputFocusOnSubmit = (elements: HTMLInputElement[]) => {
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    const isAriaInvalid = element.getAttribute("aria-invalid") === "true";

    if (!element.checkValidity() || isAriaInvalid) {
      // If the invalid element that will receive validation focus is currently in focus
      // then it won't be reanounced and hence the error won't be read.
      // In that case, first we need to blur it and the we can focus on it again
      // allowing the screen reader to read the new information relative to the error
      if (document.activeElement === element) {
        element.blur();
        // set focus on the next tick so that the browser has time to blur it
        setTimeout(() => element.focus(), 0);
      } else {
        element.focus();
      }
      break; // Exit the loop when an invalid element is found
    }
  }
};

const useFocusInvalidInput = (
  formRef: MutableRefObject<HTMLFormElement | null> | undefined,
) => {
  useEffect(() => {
    if (!formRef?.current) return;

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "aria-invalid"
        ) {
          const target = mutation.target as HTMLElement;
          if (target.getAttribute("aria-invalid") === "true") {
            target.focus(); // Focus the first invalid input
            return;
          }
        }
      });
    });

    observer.observe(formRef.current, {
      attributes: true,
      subtree: true, // Watch for attribute changes in descendants
      attributeFilter: ["aria-invalid"], // Only observe changes to aria-invalid attribute
    });

    return () => observer.disconnect();
  }, [formRef]);
};

export function FormRenderer<Props>({
  asHtmlForm,
  children,
  formStyle,
  name,
}: {
  asHtmlForm: FormRenderPropsProps<Props>["asHtmlForm"];
  children: ReactNode;
  formStyle: FormRenderPropsProps<Props>["formStyle"];
  name?: string;
}) {
  const formRef = useRef<HTMLFormElement | null>(null);

  useFocusInvalidInput(formRef);

  // TODO: migrate all forms to html forms
  if (asHtmlForm) {
    return (
      <form
        ref={formRef}
        data-testid={`${name}-form`}
        name={name}
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
        }}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          ...formStyle,
        }}
      >
        {children}
      </form>
    );
  }

  return <>{children}</>;
}

export function FormRenderProps<Props>({
  convertIn = (value) => value,
  convertOut = (value) => value,
  validate = () => true,
  modelDefinition,
  children,
  asHtmlForm,
  formStyle,
  ...props
}: FormRenderPropsProps<Props>) {
  const whitelist = useMemo(
    () =>
      modelDefinition
        ?.filter((m) => m?.show != null)
        .reduce((currentObject: any, newValue: any) => {
          currentObject[newValue.out] = newValue.show(props);
          return currentObject;
        }, {}),
    [modelDefinition],
  );

  return (
    <FormContainerWithTranslations
      {...props}
      convertIn={convertIn}
      convertOut={convertOut}
      whitelist={whitelist}
      validate={validate}
    >
      {({ dirty, onChange, resetValidation, submit, valueChangeObs }) => (
        <StateDispatcherRenderProps
          whitelist={whitelist}
          onChange={onChange}
          valueChangeObs={valueChangeObs}
        >
          {({ onChange: dispatcherOnChange }) => (
            <FormRenderer asHtmlForm={asHtmlForm} formStyle={formStyle}>
              <FormRendererContext.Provider
                value={{
                  onChange: dispatcherOnChange,
                  valueChangeObs,
                  submit,
                  dirty,
                  resetValidation,
                }}
              >
                {children({
                  onChange: dispatcherOnChange,
                  valueChangeObs,
                  submit,
                  dirty,
                  resetValidation,
                })}
              </FormRendererContext.Provider>
            </FormRenderer>
          )}
        </StateDispatcherRenderProps>
      )}
    </FormContainerWithTranslations>
  );
}

export function SimpleFormRenderProps<Props>({
  children,
  formInputValue,
  modelDefinition,
  onSubmit,
  validate,
  ...props
}: SimpleFormRenderPropsProps<Props>) {
  return (
    <FormRenderProps
      onSubmit={onSubmit}
      formInputValue={formInputValue}
      convertOut={convertOut(modelDefinition)}
      convertIn={convertIn(modelDefinition)}
      validate={validate ?? validateModel(modelDefinition)}
      modelDefinition={modelDefinition}
      {...props}
    >
      {children}
    </FormRenderProps>
  );
}

export function withForm<T extends WithFormProps = WithFormProps>(
  WrappedComponent: React.ComponentType<FormRendererContextProps & T>,
) {
  // Try to create a nice displayName for React Dev Tools.
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  const ComponentWithForm = ({
    convertIn = (value: any) => value,
    convertOut = (value: any) => value,
    modelDefinition,
    validate = () => true,
    formInputValue,
    onSubmit,
    resetAfterSubmit,
    ...props
  }: Omit<T, keyof FormRendererContextProps>) => {
    const whitelist = useMemo(
      () =>
        modelDefinition
          ?.filter((m) => m?.show != null)
          .reduce((currentObject: any, newValue: any) => {
            currentObject[newValue.out] = newValue.show(props);
            return currentObject;
          }, {}),
      [modelDefinition],
    );
    const formProps = {
      convertIn,
      convertOut,
      validate,
      whitelist,
      formInputValue,
      onSubmit,
      resetAfterSubmit,
      ...props,
    };
    return (
      <FormContainerWithTranslations {...formProps}>
        {({ dirty, onChange, resetValidation, submit, valueChangeObs }) => (
          <StateDispatcherRenderProps
            whitelist={whitelist}
            onChange={onChange}
            valueChangeObs={valueChangeObs}
          >
            {({ onChange: dispatcherOnChange }) => (
              <FormRendererContext.Provider
                value={{
                  onChange: dispatcherOnChange,
                  valueChangeObs,
                  submit,
                  dirty,
                  resetValidation,
                }}
              >
                <WrappedComponent
                  {...(props as T)}
                  onChange={dispatcherOnChange}
                  valueChangeObs={valueChangeObs}
                  submit={submit}
                  dirty={dirty}
                  resetValidation={resetValidation}
                />
              </FormRendererContext.Provider>
            )}
          </StateDispatcherRenderProps>
        )}
      </FormContainerWithTranslations>
    );
  };

  ComponentWithForm.displayName = `withForm(${displayName})`;

  return ComponentWithForm;
}

export function Form({
  convertIn = (value: any) => value,
  convertOut = (value: any) => value,
  modelDefinition,
  validate = () => true,
  resetAfterSubmit = false,
}: Omit<WithFormProps, keyof _FormProps> = {}) {
  return function withForm<T extends _FormProps = _FormProps>(
    WrappedComponent: React.ComponentType<FormRendererContextProps & T>,
  ) {
    // Try to create a nice displayName for React Dev Tools.
    const displayName =
      WrappedComponent.displayName || WrappedComponent.name || "Component";

    const ComponentWithForm = ({
      formInputValue,
      onSubmit,
      ...props
    }: Omit<T, keyof FormRendererContextProps>) => {
      const whitelist = useMemo(
        () =>
          modelDefinition
            ?.filter((m) => m?.show != null)
            .reduce((currentObject: any, newValue: any) => {
              currentObject[newValue.out] = newValue.show(props);
              return currentObject;
            }, {}),
        [modelDefinition],
      );
      const formProps = {
        convertIn,
        convertOut,
        validate,
        whitelist,
        formInputValue,
        onSubmit,
        resetAfterSubmit,
        ...props,
      };
      return (
        <FormContainerWithTranslations {...formProps}>
          {({ dirty, onChange, resetValidation, submit, valueChangeObs }) => (
            <StateDispatcherRenderProps
              whitelist={whitelist}
              onChange={onChange}
              valueChangeObs={valueChangeObs}
            >
              {({ onChange: dispatcherOnChange }) => (
                <FormRendererContext.Provider
                  value={{
                    onChange: dispatcherOnChange,
                    valueChangeObs,
                    submit,
                    dirty,
                    resetValidation,
                  }}
                >
                  <WrappedComponent
                    {...(props as T)}
                    onChange={dispatcherOnChange}
                    valueChangeObs={valueChangeObs}
                    submit={submit}
                    dirty={dirty}
                    resetValidation={resetValidation}
                  />
                </FormRendererContext.Provider>
              )}
            </StateDispatcherRenderProps>
          )}
        </FormContainerWithTranslations>
      );
    };

    ComponentWithForm.displayName = `withForm(${displayName})`;

    return ComponentWithForm;
  };
}
