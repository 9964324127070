import { List } from "immutable";

type OptionData = {
  after: string;
  before: string;
  content: string;
};
type Options = List<{
  compose: (option: OptionData | undefined) => Array<string>;
  id: string;
  syntax: string;
}>;

const optionsRegex = /{([a-zA-Z])*:/gi;

const isOption = (options: Options, option: string): boolean =>
  option != null &&
  options.find((opt) => (opt != null ? opt.syntax === option : false)) != null;

const getOptions = (str: string): Array<string> | null =>
  str.match(optionsRegex);

const getOptionIndex = (options: Options, str: string): number => {
  const withOptions = getOptions(str);
  const firstValidOption =
    withOptions?.find((t) => isOption(options, t)) || null;

  return firstValidOption ? str.indexOf(firstValidOption) : -1;
};

const getOption = (options: Options, str: string, start: number): string => {
  const option = (getOptions(str.slice(start)) || [])[0];
  return (isOption(options, option) && option) || "";
};

const getClosingBracket = (
  options: Options,
  str: string,
  optionSize: number,
): number | null => {
  let ignore: Array<number> = [];
  const chars = str.split("");

  return chars.reduce((acc: number | null, cur: string, idx: number) => {
    if (ignore.length > 0 && idx - ignore[ignore.length - 1] <= optionSize)
      return acc;
    else if (getOptionIndex(options, str.slice(idx, idx + optionSize)) >= 0)
      ignore = [...ignore, idx];
    else if (cur == "}") {
      if (ignore.length === 0)
        return -str.length + (idx + 1) === 0 ? -1 : -str.length + idx;
      ignore.pop();
    }
    return acc;
  }, null);
};

const getOptionData = (
  options: Options,
  str: string,
  optionIndex: number,
): {
  data?: {
    after: string;
    before: string;
    content: string;
  };
  id?: string;
  ignore: boolean;
} => {
  let ignore = false;
  let base = {};

  const optionLength = getOption(options, str, optionIndex).length || 0;
  const optionEndIndex = optionIndex + optionLength;
  const option = options.find((opt) =>
    opt != null ? opt.syntax === str.slice(optionIndex, optionEndIndex) : false,
  );
  const closingBracket = getClosingBracket(
    options,
    str.slice(optionEndIndex),
    optionLength,
  );

  if (closingBracket == null) {
    console.warn("No closing bracket found for option", str);
    ignore = true;
  }

  if (!ignore && option != null) {
    const content = str.slice(
      optionIndex + optionLength,
      closingBracket || undefined,
    );
    const [before, after] = str.split(
      closingBracket === -1
        ? str.slice(optionIndex)
        : str.slice(optionIndex, (closingBracket || 0) + 1),
    );

    base = {
      data: {
        before,
        after,
        content,
      },
      id: option.id,
    };
  }

  return {
    ...base,
    ignore,
  };
};

export const composeTranslation = (str: string): Array<any> => {
  /* eslint-disable-next-line */
  const options = generateOptions();
  const optionIndex = getOptionIndex(options, str);

  if (optionIndex >= 0) {
    const { data, id, ignore } = getOptionData(options, str, optionIndex);
    const match = options.find((opt) => (opt != null ? opt.id === id : false));

    if (!ignore && match != null) return match.compose(data);
  }

  return [str];
};

export const generateOptions = (): Options => {
  const options = [
    {
      id: "select",
      syntax: "{select:",
      compose: (
        { after, before, content }: OptionData = {
          before: "",
          after: "",
          content: "",
        },
      ) => {
        const pre = composeTranslation(before);
        const post = composeTranslation(after);
        const option = composeTranslation(content);

        const withNestedOption = option.length > 1;
        const array = [...pre, ...post];

        if (withNestedOption) array.splice(1, 0, option);
        else array.splice(1, 0, ...option);
        return array;
      },
    },
  ];

  return List(options);
};
