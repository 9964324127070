import { mockAccount } from "core/mocks";
import { NO_ENCRYPTION_DENIED } from "core/model/patients/encryption/utils";
import { isProd } from "core/model/utils/featureFlags";
import { Account, Careseeker, Event, SearchType } from "core/types";
import { ReactNode, createContext, useContext } from "react";

type PatientContextType = {
  auctionId: number | undefined;
  patientId: number | undefined;
  patientType: SearchType | undefined;
};

const defaultValue: PatientContextType = {
  patientId: undefined,
  auctionId: undefined,
  patientType: undefined,
};

export const PatientContext = createContext<PatientContextType>(defaultValue);

type AuctionDefaultContextType = {
  auctionRequestId: number | string | null | undefined;
  careproviderId: number | null | undefined;
};

const AuctionDefaultContext: AuctionDefaultContextType = {
  auctionRequestId: null,
  careproviderId: null,
};

export const AuctionRequestContext = createContext<AuctionDefaultContextType>(
  AuctionDefaultContext,
);

export function useAuctionRequestContext(): AuctionDefaultContextType {
  return useContext(AuctionRequestContext);
}

export type SenderAccountContextType = {
  account: Account | null;
  activeRoles: Array<number> | null;
  careseeker: Careseeker | null;
  privateKey: string | null;
  socialWorkers: Array<Account> | null;
};

const defaultSenderAccountContext = {
  account: null,
  careseeker: null,
  activeRoles: null,
  socialWorkers: null,
  privateKey: null,
};

export const SenderAccountContext = createContext<SenderAccountContextType>(
  defaultSenderAccountContext,
);

export function useSenderAccountContext() {
  const context = useContext(SenderAccountContext);
  if (!isProd && (!context || context === defaultSenderAccountContext)) {
    console.error(
      "You're attempting to use useSenderAccountContext outside of SenderAccountContext.Provider",
    );
  }
  return context;
}

export const PrintContext = createContext<boolean>(false);

export function usePrint() {
  return useContext(PrintContext);
}

type EncryptionContextType = {
  accessDenied?: boolean;
  account?: Account;
  encryptionError?: string;
  events?: Array<Event> | null;
  hasEncryption: boolean;
  isSealdOnly?: boolean;
  userSupportsEncryption?: boolean;
};

export const EncryptionContext = createContext<EncryptionContextType>({
  userSupportsEncryption: undefined,
  hasEncryption: false,
  events: null,
  account: undefined,
  accessDenied: false,
  encryptionError: undefined,
  isSealdOnly: false,
});

export const EncryptionKeyContext = createContext<CryptoKey | null>(null);

export function useEncryption(): EncryptionContextType {
  return useContext(EncryptionContext);
}

export function useEncryptionKey(): CryptoKey | null {
  return useContext(EncryptionKeyContext);
}

export function usePatientEncryption(): {
  encryptionAvailable: boolean;
  reason?: string;
} {
  const { accessDenied, hasEncryption } = useEncryption();

  if (hasEncryption && !accessDenied)
    return {
      encryptionAvailable: true,
    };

  return {
    encryptionAvailable: false,
    reason: NO_ENCRYPTION_DENIED,
  };
}

export function MockedEncryptionProvider({
  account,
  children,
  events,
  hasEncryption,
}: {
  account?: Account;
  children: ReactNode;
  events: Event[];
  hasEncryption?: boolean;
}) {
  return (
    <EncryptionContext.Provider
      value={{
        accessDenied: false,
        account: account || mockAccount({ id: 1 }),
        events,
        hasEncryption: !!hasEncryption,
        userSupportsEncryption: true,
        isSealdOnly: false,
      }}
    >
      {children}
    </EncryptionContext.Provider>
  );
}
