import {
  ACADEMIC_TITLE_DOCTOR,
  ACADEMIC_TITLE_DOCTOR_DOCTOR_MEDICINE,
  ACADEMIC_TITLE_DOCTOR_MEDICINE,
  ACADEMIC_TITLE_DOCTOR_MULTIPLEX,
  ACADEMIC_TITLE_PROFESSOR,
  ACADEMIC_TITLE_PROFESSOR_DOCTOR,
  ACADEMIC_TITLE_PROFESSOR_ORDINARIUS,
  ACCOUNT_ROLE_ADMIN,
  ACCOUNT_ROLE_ADMIN_EDIT_CAREPROVIDER,
  ACCOUNT_ROLE_ADMIN_EDIT_CARESEEKER,
  ACCOUNT_ROLE_ADMIN_EDIT_CONSULTANT,
  ACCOUNT_ROLE_ADMIN_READ_ONLY,
  ACCOUNT_ROLE_ADMIN_TRANSLATIONS,
  ACCOUNT_ROLE_CARESEEKER_CARE,
  ACCOUNT_ROLE_CARESEEKER_HOSPITALIZATION,
  ACCOUNT_ROLE_CARESEEKER_REHAB,
  ACCOUNT_ROLE_CARESEEKER_SSO_USER_MANAGEMENT,
  ACCOUNT_ROLE_CARESEEKER_USER_MANAGEMENT,
  ACCOUNT_ROLE_COST_APPROVER,
  ACCOUNT_ROLE_MEDICAL_SUPPLIES,
  ACCOUNT_ROLE_RECEIVER_ANALYTICS,
  ACCOUNT_ROLE_RECEIVER_BASE_ROLE,
  ACCOUNT_ROLE_RECEIVER_CONTACT,
  ACCOUNT_ROLE_REPORT,
  ACCOUNT_ROLE_SENDER_API_CREATE_PATIENT,
  ACCOUNT_ROLE_SENDER_IT,
  ACCOUNT_ROLE_TRANSPORT,
  ACCOUNT_STATUS_ACTIVE,
  ACCOUNT_STATUS_CREATED,
  ACCOUNT_STATUS_INACTIVE,
  ACCOUNT_TYPE_API,
  ACCOUNT_TYPE_CALLCENTER_DEPRECATED,
  ACCOUNT_TYPE_CAREPROVIDER_DEPRECATED,
  ACCOUNT_TYPE_CLINIC_DEPRECATED,
  ACCOUNT_TYPE_EXTERNAL,
  ACCOUNT_TYPE_PROVIDER_SEARCH,
  ACCOUNT_TYPE_STAFF,
  ACCOUNT_TYPE_TOOL,
  AUCTION_REQUEST_ACCEPTED,
  AUCTION_REQUEST_ATTRIBUTED,
  AUCTION_REQUEST_CREATED,
  AUCTION_REQUEST_DECLINED,
  AUCTION_REQUEST_REJECTED,
  AUCTION_REQUEST_SENT,
  AUCTION_REQUEST_UNAVAILABLE,
  CANDIDATES_STATUS_FAILED,
  CANDIDATES_STATUS_NOT_STARTED,
  CANDIDATES_STATUS_PENDING,
  CANDIDATES_STATUS_SUCCESS,
  CARDIOVASCULAR_EQUIPMENT,
  CARELEVEL_FIVE,
  CARELEVEL_FOUR,
  CARELEVEL_NONE,
  CARELEVEL_ONE,
  CARELEVEL_THREE,
  CARELEVEL_TWO,
  CARELEVEL_UNKNOWN,
  CAREPROVIDER_ACTIVE,
  CAREPROVIDER_CREATED,
  CAREPROVIDER_DUPLICATE,
  CAREPROVIDER_INACTIVE,
  CAREPROVIDER_INVALID,
  CAREPROVIDER_PENDING_ACCOUNT_ACTIVATION,
  CAREPROVIDER_PROFILE_FILLED_DEPRECATED,
  CAREPROVIDER_TEST_NOT_ANSWERED_DEPRECATED,
  CAREPROVIDER_TEST_SENT_DEPRECATED,
  CAREPROVIDER_TRASH,
  CARESEEKER_STATUS_ACTIVE,
  CARESEEKER_STATUS_CREATED,
  CARESEEKER_STATUS_DUPLICATE,
  CARESEEKER_STATUS_INACTIVE,
  CARESEEKER_STATUS_ONBOARDING,
  CARESEEKER_STATUS_SALES,
  CARESEEKER_STATUS_TRASH,
  CARE_DURATION_FIVE_MONTHS,
  CARE_DURATION_FOUR_MONTHS,
  CARE_DURATION_ONE_DAY,
  CARE_DURATION_ONE_MONTH,
  CARE_DURATION_ONE_WEEK,
  CARE_DURATION_SIX_MONTHS_PLUS,
  CARE_DURATION_THREE_MONTHS,
  CARE_DURATION_THREE_WEEKS,
  CARE_DURATION_TWO_MONTHS,
  CARE_DURATION_TWO_WEEKS,
  CARE_DURATION_UNKNOWN,
  COMMUNICATION_BAD,
  COMMUNICATION_GOOD,
  COMMUNICATION_MODERATE,
  COMPANY_STATUSES,
  COMPANY_TYPES,
  CONSULTANT_STATUS_ACTIVE,
  CONSULTANT_STATUS_CREATED,
  CONSULTANT_STATUS_INACTIVE,
  COST_PAYER_TYPE_PRIVATE_HEALTH_INSURANCE,
  COST_PAYER_TYPE_PUBLIC_HEALTH_INSURANCE,
  DIGESTIVE_ENDOSCOPY,
  ELIGIBILITY_REJECT_ANOTHER_FORM_OF_REHAB_SOUGHT,
  ELIGIBILITY_REJECT_DIFFERENT_COST_PAYER_RESPONSIBLE,
  ELIGIBILITY_REJECT_NOT_ELIGIBLE,
  ELIGIBILITY_REJECT_OTHER,
  EMPLOYMENT_STATUS_CIVIL_SERVANT,
  EMPLOYMENT_STATUS_EMPLOYEE,
  EMPLOYMENT_STATUS_MASTER_FOREMAN,
  EMPLOYMENT_STATUS_NOT_EMPLOYED,
  EMPLOYMENT_STATUS_SELF_EMPLOYED,
  EMPLOYMENT_STATUS_SEMI_SKILLED_WORKER,
  EMPLOYMENT_STATUS_SKILLED_WORKER,
  EMPLOYMENT_STATUS_TRAINEE,
  EMPLOYMENT_STATUS_UNSKILLED_EMPLOYEE,
  FACILITY_ASSISTED_LIVING,
  FACILITY_COMPANIONSHIP,
  FACILITY_DAILY_LIVING_SUPPORT_SERVICES,
  FACILITY_DAY_CARE,
  FACILITY_DAY_HOSPITALIZATION,
  FACILITY_FULLTIME_COMPANIONSHIP,
  FACILITY_FULLTIME_MOBILE_CARE,
  FACILITY_FULL_HOSPITALIZATION,
  FACILITY_HOME_CARE,
  FACILITY_HOSPICE_ASSOCIATION,
  FACILITY_HOSPICE_MOBILE,
  FACILITY_HOSPICE_PARTIALLY_MOBILE,
  FACILITY_HOSPICE_STATIC,
  FACILITY_KTW_CARRY_CHAIR,
  FACILITY_KTW_LYING_DOWN,
  FACILITY_KTW_MEDICAL_CARE,
  FACILITY_KTW_WHEELCHAIR,
  FACILITY_MEDICAL_SUPPLIES,
  FACILITY_MOBILE_CARE,
  FACILITY_NIGHT_CARE_MOBILE,
  FACILITY_NIGHT_CARE_STATIC,
  FACILITY_PURE_INTENSIVE_CARE,
  FACILITY_REHAB_DAY_HOSPITALIZATION,
  FACILITY_REHAB_FULL_HOSPITALIZATION,
  FACILITY_REHAB_MOBILE,
  FACILITY_RENTAL_CAR_LYING_DOWN,
  FACILITY_RENTAL_CAR_WHEELCHAIR,
  FACILITY_SHARED_FLAT,
  FACILITY_SHORT_TERM_CARE,
  FACILITY_STATIC_CARE,
  FACILITY_TAXI,
  FACILITY_TAXI_CARRY_CHAIR,
  FILE_CATEGORY_AD,
  FILE_CATEGORY_AND,
  FILE_CATEGORY_APF,
  FILE_CATEGORY_BIX,
  FILE_CATEGORY_BTR,
  FILE_CATEGORY_CC,
  FILE_CATEGORY_CTB,
  FILE_CATEGORY_ECG,
  FILE_CATEGORY_ERR,
  FILE_CATEGORY_FSO,
  FILE_CATEGORY_GI,
  FILE_CATEGORY_IMG,
  FILE_CATEGORY_ISO,
  FILE_CATEGORY_MDL,
  FILE_CATEGORY_MP,
  FILE_CATEGORY_MR,
  FILE_CATEGORY_MSR,
  FILE_CATEGORY_PCR,
  FILE_CATEGORY_PR,
  FILE_CATEGORY_PRE,
  FILE_CATEGORY_RBA,
  FILE_CATEGORY_RER,
  FILE_CATEGORY_SN,
  FILE_CATEGORY_WP,
  FILE_ERROR_FILE_SIZE,
  FILE_ERROR_FILE_TYPE,
  FILE_SHARE_MODE_ALL,
  FILE_SHARE_MODE_NONE,
  FILE_SHARE_MODE_SELECTED,
  FILTER_AGE,
  FILTER_BLACKLISTED_INSURANCE,
  FILTER_CAPACITY_BACKOFF,
  FILTER_CAPACITY_START_DATE,
  FILTER_CONTACTED,
  FILTER_DISTANCE,
  FILTER_NO_BED_AVAILABLE,
  FILTER_PREREQUISITE,
  FILTER_RECEIVER_BLACKLISTED,
  FILTER_REMOVED_SOLUTION,
  FILTER_SENDER_BLACKLISTED,
  FILTER_SERVICE,
  FILTER_SOLUTION,
  FILTER_SPECIALIZATION,
  FILTER_STATUS,
  FILTER_THROTTLE,
  FILTER_ZIPCODES,
  FORM_YES_NO_NEGATIVE,
  FORM_YES_NO_POSITIVE,
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_OTHER,
  GRANTS_COMBINATION_OF_BOTH,
  GRANTS_MONEY_FROM_INSURANCE,
  GRANTS_MONEY_FROM_STATE,
  GRANT_STATUS_APPLIED_FOR,
  GRANT_STATUS_EXISTS,
  GRANT_STATUS_NOT_APPLIED,
  HOME_CARE_SPECIALIZATION_COMPRESSION_THERAPY,
  HOME_CARE_SPECIALIZATION_DECUBITUS_PROPHYLAXIS,
  HOME_CARE_SPECIALIZATION_ENTERAL_NUTRITION,
  HOME_CARE_SPECIALIZATION_INCONTINENCE,
  HOME_CARE_SPECIALIZATION_INCONTINENCE_DISCHARGING,
  HOME_CARE_SPECIALIZATION_INFUSION_THERAPY,
  HOME_CARE_SPECIALIZATION_MONITORING,
  HOME_CARE_SPECIALIZATION_OXYGEN,
  HOME_CARE_SPECIALIZATION_PAIN_THERAPY,
  HOME_CARE_SPECIALIZATION_PARENTERAL_NUTRITION,
  HOME_CARE_SPECIALIZATION_RESPIRATORY_THERAPY,
  HOME_CARE_SPECIALIZATION_STOMA,
  HOME_CARE_SPECIALIZATION_TRACHEOSTOMY_THERAPY,
  HOME_CARE_SPECIALIZATION_VENTILATION,
  HOME_CARE_SPECIALIZATION_WOUND_CARE,
  IMAGERY,
  INFECTION_AND_GERMS_REQUIRES_CLOSTRIDIEN,
  INFECTION_AND_GERMS_REQUIRES_FOUR_MRGN,
  INFECTION_AND_GERMS_REQUIRES_ISOLATION,
  INFECTION_AND_GERMS_REQUIRES_MRSA,
  INFECTION_AND_GERMS_REQUIRES_THREE_MRGN,
  INFECTION_AND_GERMS_REQUIRES_VRE,
  INF_SYS_CONNEXT_VIVENDI,
  INF_SYS_CURASOFT,
  INF_SYS_DAN_PRODUKTE,
  INF_SYS_GODO_SYSTEMS,
  INF_SYS_MEDIFOX,
  INF_SYS_MYNEVA,
  INF_SYS_NOVENTI_CARE,
  INF_SYS_OTHER,
  INF_SYS_SENSO,
  INF_SYS_SINFONIE,
  INF_SYS_SNAP,
  LANGUAGE_AFGHAN,
  LANGUAGE_ALBANIAN,
  LANGUAGE_AMHARIC,
  LANGUAGE_ARABIC,
  LANGUAGE_AZERBAIJANI,
  LANGUAGE_BENGAL,
  LANGUAGE_BOSNIAN,
  LANGUAGE_BULGARIAN,
  LANGUAGE_CANTONESE,
  LANGUAGE_CROATIAN,
  LANGUAGE_CZECH,
  LANGUAGE_DANISH,
  LANGUAGE_DUTCH,
  LANGUAGE_ENGLISH,
  LANGUAGE_FINNISH,
  LANGUAGE_FRENCH,
  LANGUAGE_GEORGIAN,
  LANGUAGE_GERMAN,
  LANGUAGE_GREEK,
  LANGUAGE_HEBREW,
  LANGUAGE_HINDI,
  LANGUAGE_HUNGARIAN,
  LANGUAGE_INDONESIAN,
  LANGUAGE_ITALIAN,
  LANGUAGE_JAPANESE,
  LANGUAGE_KOREAN,
  LANGUAGE_KURDISH,
  LANGUAGE_LITHUNIAN,
  LANGUAGE_MANDARIN,
  LANGUAGE_NORWEGIAN,
  LANGUAGE_PERSIAN,
  LANGUAGE_POLISH,
  LANGUAGE_PORTUGUESE,
  LANGUAGE_ROMANIAN,
  LANGUAGE_RUSSIAN,
  LANGUAGE_SERBIAN,
  LANGUAGE_SIGN,
  LANGUAGE_SPANISH,
  LANGUAGE_SWAHILI,
  LANGUAGE_SWEDISH,
  LANGUAGE_THAI,
  LANGUAGE_TIGRINYA,
  LANGUAGE_TURKISH,
  LANGUAGE_URDU,
  LANGUAGE_URKRAINIAN,
  LANGUAGE_VIETNAMESE,
  LIVING_SITUATION_ALONE,
  LIVING_SITUATION_CAREHOME,
  LIVING_SITUATION_IN_ELDERLY_HOME,
  LIVING_SITUATION_OTHER,
  LIVING_SITUATION_SHARED_FLAT,
  LIVING_SITUATION_WITH_FAMILY,
  MAIL_TYPES,
  MARITAL_STATUS_DIVORCED,
  MARITAL_STATUS_MARRIED,
  MARITAL_STATUS_SINGLE,
  MARITAL_STATUS_WIDOWED,
  MOBILITY_CRUTCH,
  MOBILITY_MEDICALISED_BED,
  MOBILITY_ROLLATOR,
  MOBILITY_WALKING_FRAME,
  MOBILITY_WALKING_STICK,
  MOBILITY_WALK_WITHOUT_HELP,
  MOBILITY_WALK_WITH_HELP,
  MOBILITY_WHEELCHAIR,
  MOTIVATION_BAD,
  MOTIVATION_GOOD,
  MOTIVATION_MODERATE,
  ONBOARDING_ADMINISTRATION_PHASE,
  ONBOARDING_CHAIN,
  ONBOARDING_COMPLICATED_DEPRECATED,
  ONBOARDING_CONSIDERATION_PHASE,
  ONBOARDING_INFO_PHASE,
  ONBOARDING_IT_ISSUES_DEPRECATED,
  ONBOARDING_LATE_DEPRECATED,
  ONBOARDING_MANUALLY_ADDED_BY_SENDER,
  ONBOARDING_MISSING_INFORMATION,
  ONBOARDING_NOT_REACHED_CALLCENTER_DEPRECATED,
  ONBOARDING_NOT_REACHED_DEPRECATED,
  ONBOARDING_NO_ACTION_FROM_CALLCENTER_DEPRECATED,
  ONBOARDING_NO_COMMENT_DEPRECATED,
  ONBOARDING_NO_INTEREST_DEPRECATED,
  ONBOARDING_OTHER_DEPRECATED,
  ONBOARDING_PREONBOARDING,
  ONBOARDING_PROFILE_FILLED_DEPRECATED,
  ONBOARDING_RESEND_ONBOARDING_EMAIL_DEPRECATED,
  ONBOARDING_SELF_ONBOARDING,
  ONBOARDING_TO_CALL,
  ONBOARDING_TO_CALL_BACK_DEPRECATED,
  ORIENTATION_DISORIENTED,
  ORIENTATION_LIMITED,
  ORIENTATION_ORIENTATED,
  OTHER_EQUIPMENT,
  PAGINATION_COUNT_10,
  PAGINATION_COUNT_20,
  PAGINATION_COUNT_30,
  PRIOR_EMPLOYMENT_FULL_TIME_NO_ROTATING_SHIFT,
  PRIOR_EMPLOYMENT_FULL_TIME_ROTATING_SHIFT,
  PRIOR_EMPLOYMENT_HOUSEHOLD_CARE,
  PRIOR_EMPLOYMENT_NOT_WORKING,
  PRIOR_EMPLOYMENT_PART_TIME_LESS_THAN_HALF_DAY,
  PRIOR_EMPLOYMENT_PART_TIME_MORE_THAN_HALF_DAY,
  PRIOR_EMPLOYMENT_UNEMPLOYED_REGISTERED,
  PRIOR_EMPLOYMENT_WITH_NIGHT_SHIFTS,
  PRIOR_EMPLOYMENT_WORKSHOP_FOR_DISABLED_PEOPLE,
  PRIOR_EMPLOYMENT_WORK_AT_HOME,
  PRODUCT_FEATURE_ADMISSIONS,
  PRODUCT_FEATURE_ANALYTICS,
  PRODUCT_FEATURE_TRANSITIONAL_CARE,
  PROVIDER_SEARCH_BAVARIA,
  RECEIVER_INACTIVE_REASONS,
  REHABILITATION_POTENTIAL_EVALUATION_EXTREMELY_NEGATIVE,
  REHABILITATION_POTENTIAL_EVALUATION_EXTREMELY_POSITIVE,
  REHABILITATION_POTENTIAL_EVALUATION_MODERATE,
  REHABILITATION_POTENTIAL_EVALUATION_NEGATIVE,
  REHABILITATION_POTENTIAL_EVALUATION_POSITIVE,
  RELIEF_SERVICES_PARAGRAPH_A,
  RELIEF_SERVICES_PARAGRAPH_B,
  REQUEST_CHIP_STATUS_FAX_SENT,
  REQUEST_CHIP_STATUS_NEW_FILE,
  REQUEST_CHIP_STATUS_NEW_MESSAGE,
  REQUEST_CHIP_STATUS_ONGOING_CHAT,
  REQUEST_CHIP_STATUS_PROVIDER_INACTIVE,
  REQUEST_CHIP_STATUS_REQUEST_NOT_SEEN,
  REQUEST_CHIP_STATUS_REQUEST_SEEN,
  REQUEST_CHIP_STATUS_SENT_MESSAGE_NOT_SEEN,
  REQUEST_CHIP_STATUS_SENT_MESSAGE_SEEN,
  ROOM_TYPE_ACCOMPANYING_CHILD,
  ROOM_TYPE_ACCOMPANYING_PERSON,
  ROOM_TYPE_ACCOMPANYING_SIBLING,
  ROOM_TYPE_APPARTMENT,
  ROOM_TYPE_BARRIER_FREE,
  ROOM_TYPE_DOUBLE_ROOM,
  ROOM_TYPE_NO_PREFERENCE,
  ROOM_TYPE_SINGLE_STANDARD,
  SEARCH_TABLE_ROW_COUNT_10,
  SEARCH_TABLE_ROW_COUNT_30,
  SEARCH_TABLE_ROW_COUNT_50,
  SEARCH_TABLE_SORTING_DEFAULT,
  SEARCH_TABLE_SORTING_DISTANCE,
  SEARCH_TABLE_SORTING_NAME,
  SEARCH_TYPE_CARE,
  SEARCH_TYPE_HOME_CARE,
  SEARCH_TYPE_HOSPITAL,
  SEARCH_TYPE_MEDICAL_SUPPLIES,
  SEARCH_TYPE_REHABILITATION,
  SEARCH_TYPE_TRANSPORT,
  SERVICE_BASIC_CARE,
  SERVICE_BASIC_MEDICAL_CARE,
  SERVICE_CARE_PROTECTED_AREA,
  SERVICE_CLOSED_AREA,
  SERVICE_COMPANIONSHIP,
  SERVICE_DEMENTIA,
  SERVICE_DIALYSIS,
  SERVICE_ENTERALNUTRITION,
  SERVICE_HEARING_IMPAIRED,
  SERVICE_HELPWITHEATING,
  SERVICE_INVASIVEVENTILATION,
  SERVICE_MONITORINGBREATHING,
  SERVICE_MONITORINGCIRCULATION,
  SERVICE_MONITORINGMETABOLISM,
  SERVICE_NONINVASIVEVENTILATION,
  SERVICE_OXYGENTHERAPY,
  SERVICE_PALLIATIVE_CARE,
  SERVICE_PALLIATIVE_CERTIFICATE,
  SERVICE_PARENTERAL_NUTRITRION,
  SERVICE_PEOPLEWHOAREDANGEROUS,
  SERVICE_PEOPLEWITHADDICTIONS,
  SERVICE_PEOPLEWITHORIENTATIONDISORDER,
  SERVICE_PROTECTEDAREA,
  SERVICE_PSYCHIATRIC_CARE,
  SERVICE_SPECIALISED_INTENSIVE_NURSE,
  SERVICE_STATIC_CARE_DISABLED_PATIENTS,
  SERVICE_TAKINGOVERBODYCARE,
  SERVICE_TRACHEOSTOMY,
  SERVICE_VISUALLY_IMPAIRED,
  SHORT_TERM_CARE_GRANT_39,
  SHORT_TERM_CARE_GRANT_42,
  SHORT_TERM_CARE_GRANT_COMBINATION,
  SOLUTION_ASSISTED_LIVING,
  SOLUTION_DAILY_LIVING_SUPPORT_SERVICES,
  SOLUTION_DAY_CARE,
  SOLUTION_DAY_HOSPITALIZATION,
  SOLUTION_FULL_HOSPITALIZATION,
  SOLUTION_FULL_TIME_MOBILE_CARE,
  SOLUTION_HELP_AT_HOME,
  SOLUTION_HOME_CARE,
  SOLUTION_HOSPICE_ASSOCIATION,
  SOLUTION_HOSPICE_MOBILE,
  SOLUTION_HOSPICE_PARTIALLY_MOBILE,
  SOLUTION_HOSPICE_STATIC,
  SOLUTION_KTW_CARRY_CHAIR,
  SOLUTION_KTW_LYING_DOWN,
  SOLUTION_KTW_WHEELCHAIR,
  SOLUTION_KTW_WITH_MEDICAL_CARE,
  SOLUTION_MEDICAL_SUPPLIES,
  SOLUTION_MOBILE_CARE,
  SOLUTION_NIGHT_CARE_MOBILE,
  SOLUTION_NIGHT_CARE_STATIC,
  SOLUTION_REHAB_DAY_HOSPITALIZATION,
  SOLUTION_REHAB_DAY_HOSPITALIZATION_WITH_TRANSPORT_DEPRECATED,
  SOLUTION_REHAB_FULL_HOSPITALIZATION,
  SOLUTION_REHAB_MOBILE,
  SOLUTION_RENTAL_CAR_CARRY_CHAIR,
  SOLUTION_RENTAL_CAR_LYING_DOWN,
  SOLUTION_RENTAL_CAR_WHEELCHAIR,
  SOLUTION_SHARED_FLAT,
  SOLUTION_SHORT_TERM_STATIC_CARE,
  SOLUTION_STATIC_CARE,
  SOLUTION_TAXI,
  SPECIALIZATION_ACUTE_GERIATRICS,
  SPECIALIZATION_ADDICTION,
  SPECIALIZATION_ADDICTOLOGY,
  SPECIALIZATION_ALLERGOLOGY,
  SPECIALIZATION_ANESTHESIOLOGY,
  SPECIALIZATION_ANGIOLOGY,
  SPECIALIZATION_CARDIOLOGICAL_EARLY_REHA,
  SPECIALIZATION_CARDIOLOGY,
  SPECIALIZATION_COGNITIVE_BEHAVIORAL_UNIT,
  SPECIALIZATION_DERMATOLOGY,
  SPECIALIZATION_DIABETIC_FOOT,
  SPECIALIZATION_DIABETOLOGY,
  SPECIALIZATION_DIGESTIVE_SURGERY,
  SPECIALIZATION_ENDOCRINOLOGY,
  SPECIALIZATION_ENT,
  SPECIALIZATION_GASTROENTEROLOGY,
  SPECIALIZATION_GENERAL_SURGERY,
  SPECIALIZATION_GERIATRICS,
  SPECIALIZATION_GYNAECOLOGY,
  SPECIALIZATION_HAEMATOLOGY,
  SPECIALIZATION_HEPATOLOGY,
  SPECIALIZATION_ICU_CARDIOLOGY,
  SPECIALIZATION_ICU_ECMO_CORONA,
  SPECIALIZATION_ICU_GASTROLOGY,
  SPECIALIZATION_ICU_HIGH_CORONA,
  SPECIALIZATION_ICU_LOW_CORONA,
  SPECIALIZATION_ICU_NEUROVASCULAR,
  SPECIALIZATION_INFECTIOUS_DISEASE,
  SPECIALIZATION_INTERNAL_MEDICAL_EARLY_REHA,
  SPECIALIZATION_INTERNAL_MEDICINE,
  SPECIALIZATION_MAXILLOFACIAL_SURGERY,
  SPECIALIZATION_MEDICAL_REANIMATION,
  SPECIALIZATION_NEPHROLOGY,
  SPECIALIZATION_NEUROLOGY,
  SPECIALIZATION_NEUROPEDIATRIY,
  SPECIALIZATION_NEUROSURGERY,
  SPECIALIZATION_NORMAL_STATION,
  SPECIALIZATION_OBESITY,
  SPECIALIZATION_ONCOLOGY,
  SPECIALIZATION_ONCOLOGY_BREAST_GLANDS,
  SPECIALIZATION_ONCOLOGY_CENTRAL_NERVOUS_SYSTEM,
  SPECIALIZATION_ONCOLOGY_DIGESTIVE_ORGANS,
  SPECIALIZATION_ONCOLOGY_FEMALE_GENITAL,
  SPECIALIZATION_ONCOLOGY_KIDNEY_URINARY_MALE_GENITAL,
  SPECIALIZATION_ONCOLOGY_LOCOMOTOR_ORGANS,
  SPECIALIZATION_ONCOLOGY_MALIGNANT_SYSTEMIC_DISEASES,
  SPECIALIZATION_ONCOLOGY_PHARYNX_LARYNX,
  SPECIALIZATION_ONCOLOGY_RESPIRATORY_ORGANS,
  SPECIALIZATION_ONCOLOGY_SKIN,
  SPECIALIZATION_ONCOLOGY_THYROID,
  SPECIALIZATION_OPHTHALMOLOGY,
  SPECIALIZATION_ORTHOGERIATRICS,
  SPECIALIZATION_ORTHOPEDICS,
  SPECIALIZATION_ORTHOPEDICS_LOWER_LIMBS,
  SPECIALIZATION_ORTHOPEDICS_SOS_MAINS,
  SPECIALIZATION_ORTHOPEDICS_SPINE,
  SPECIALIZATION_ORTHOPEDICS_UPPER_LIMBS,
  SPECIALIZATION_PALLIATIVE_CARE,
  SPECIALIZATION_PARENTS_AND_CHILD_REHAB,
  SPECIALIZATION_PEDIATRICS,
  SPECIALIZATION_PHASEB,
  SPECIALIZATION_PHASEC,
  SPECIALIZATION_PHASECPLUS,
  SPECIALIZATION_PHASED,
  SPECIALIZATION_PHYSICAL_MEDICINE,
  SPECIALIZATION_PLASTIC_SURGERY,
  SPECIALIZATION_PNEUMOLOGICAL_EARLY_REHA,
  SPECIALIZATION_PNEUMOLOGY,
  SPECIALIZATION_PSYCHIATRY,
  SPECIALIZATION_PSYCHOSOMATIC,
  SPECIALIZATION_RADIOLOGY,
  SPECIALIZATION_RHEUMATISM,
  SPECIALIZATION_SURGICAL_RESUSCITATION,
  SPECIALIZATION_THORACIC_SURGERY,
  SPECIALIZATION_TRAUMA_SURGERY,
  SPECIALIZATION_UROLOGY,
  SPECIALIZATION_VASCULAR_SURGERY,
  SPECIALIZATION_WEANING,
  TECHNICAL_EQUIPMENT_CARDIAC_MRI,
  TECHNICAL_EQUIPMENT_COLONOSCOPY,
  TECHNICAL_EQUIPMENT_CORONAROGRAPHY,
  TECHNICAL_EQUIPMENT_CORONARY_SCANNER,
  TECHNICAL_EQUIPMENT_CTSCAN,
  TECHNICAL_EQUIPMENT_DIALYSIS,
  TECHNICAL_EQUIPMENT_DOPPLERS,
  TECHNICAL_EQUIPMENT_ENDOSCOPIC_ULTRASOUND,
  TECHNICAL_EQUIPMENT_ERCP,
  TECHNICAL_EQUIPMENT_EXERCISE_TEST,
  TECHNICAL_EQUIPMENT_GASTROSCOPY,
  TECHNICAL_EQUIPMENT_HOLTER_ECG,
  TECHNICAL_EQUIPMENT_INTERVENTION_RADIOLOGY,
  TECHNICAL_EQUIPMENT_MONITORING_BREATHING,
  TECHNICAL_EQUIPMENT_MONITORING_CIRCULATION,
  TECHNICAL_EQUIPMENT_MONITORING_METABOLISM,
  TECHNICAL_EQUIPMENT_MRI,
  TECHNICAL_EQUIPMENT_RYTHMOLOGY,
  TECHNICAL_EQUIPMENT_TOE,
  TECHNICAL_EQUIPMENT_TTE,
  TECHNICAL_EQUIPMENT_ULTRASOUND,
  TECHNICAL_EQUIPMENT_XRAY,
  TRANSPORT_TYPE_AMBULANCE_LYING_DOWN,
  TRANSPORT_TYPE_AMBULANCE_SITTING,
  TRANSPORT_TYPE_EMERGENCY_VEHICLE,
  TRANSPORT_TYPE_HELICOPTER,
  TRANSPORT_TYPE_PERSONAL_CAR,
  TRANSPORT_TYPE_PUBLIC_TRANSPORT,
  TRANSPORT_TYPE_TAXI,
  UNIVERSAL_SEARCH_RESOURCES,
  VIGILANCE_AGITATED,
  VIGILANCE_AWAKE,
  VIGILANCE_CALM,
  VIGILANCE_COMATOSE,
  VIGILANCE_RESPONSIVE,
  VIGILANCE_SOMNOLENT,
  VIGILANCE_SOPORIFIC,
  ACCOUNT_ROLE_RECEIVER_ADMISSIONS,
} from "core/consts";
import { InnerValues } from "core/types";
import Translations from "translations/types";

export type OntologyType = keyof typeof ontologies;
export type OntologyStructValue = InnerValues<
  typeof ontologies,
  keyof typeof ontologies
>;

export const ONTOLOGY_ACADEMIC_TITLE = "academicTitle";
export const ONTOLOGY_CANDIDATES_STATUS = "candidatesProgress";
export const ONTOLOGY_CARDIOVASCULAR_EQUIPMENT = "cardiovascularExploration";
export const ONTOLOGY_CARE_DURATIONS = "careDurations";
export const ONTOLOGY_CARE_LEVEL = "carelevel";
export const ONTOLOGY_COMMUNICATION = "communication";
export const ONTOLOGY_DIGESTIVE_ENDOSCOPY = "digestiveEndoscopy";
export const ONTOLOGY_EMPLOYMENT_STATUS = "employmentStatus";
export const ONTOLOGY_FILE_CATEGORY = "fileCategory";
export const ONTOLOGY_FILE_ERROR = "fileError";
export const ONTOLOGY_FILE_SHARE_MODE = "fileShareMode";
export const ONTOLOGY_FORM_YES_NO = "formYesNo";
export const ONTOLOGY_GENDER = "gender";
export const ONTOLOGY_GENDER_EXTENDED = "genderExtended";
export const ONTOLOGY_IMAGERY = "imagery";
export const ONTOLOGY_MARITAL_STATUS = "maritalStatus";
export const ONTOLOGY_MOBILITY = "mobility";
export const ONTOLOGY_MOTIVATION = "motivation";
export const ONTOLOGY_ORIENTATION = "orientation";
export const ONTOLOGY_OTHER_TECHNICAL_EQUIPMENT = "otherTechnicalEquipment";
export const ONTOLOGY_PRIOR_EMPLOYMENT_STATUS = "priorEmploymentStatus";
export const ONTOLOGY_REHABILITATION_POTENTIAL = "rehabilitationPotential";
export const ONTOLOGY_ROOM_TYPE = "roomType";
export const ONTOLOGY_ROWS_PER_PAGE = "rowsPerPage";
export const ONTOLOGY_SALUTATION = "salutation";
export const ONTOLOGY_SERVICE = "service";
export const ONTOLOGY_SOLUTION = "solution";
export const ONTOLOGY_SOLUTION_B2C = "solutionBToC";
export const ONTOLOGY_SPECIALIZATIONS = "specializations";
export const ONTOLOGY_TABLE_SORTING = "tableSorting";
export const ONTOLOGY_TRANSPORT_TYPE = "transportType";
export const ONTOLOGY_INFORMATION_SYSTEM = "informationSystems";
export const ONTOLOGY_PROVIDER_SEARCH_REQUEST_STATUS =
  "providerSearchRequestStatus";
export const ONTOLOGY_PAGINATION_RESULT_COUNT = "paginationResultCount";
export const ONTOLOGY_PROVIDER_SEARCH_NAV_TITLE = "providerSearchNavTitle";
export const ONTOLOGY_SERVICE_EXPLANATIONS = "serviceExplanations";
export const ONTOLOGY_UNIVERSAL_SEARCH_RESOURCE = "universalSearch";
export const ONTOLOGY_CONSULTANT_STATUS = "consultantStatus";
export const ONTOLOGY_TRANSITIONAL_CARE_REQUEST_STATUS =
  "transitionalCareRequestStatus";
export const ONTOLOGY_INSURANCE_TYPE = "healthInsuranceType";
export const ONTOLOGY_LANGUAGE = "language";
export const ONTOLOGY_PRODUCT_FEATURE = "productFeature";

const accountRolesOntologies = {
  [ACCOUNT_ROLE_ADMIN]: "admin",
  [ACCOUNT_ROLE_ADMIN_READ_ONLY]: "adminReadOnly",
  [ACCOUNT_ROLE_ADMIN_EDIT_CAREPROVIDER]: "adminEditCareprovider",
  [ACCOUNT_ROLE_ADMIN_EDIT_CARESEEKER]: "adminEditCareseeker",
  [ACCOUNT_ROLE_ADMIN_TRANSLATIONS]: "adminTranslations",
  [ACCOUNT_ROLE_RECEIVER_BASE_ROLE]: "provider",
  [ACCOUNT_ROLE_RECEIVER_CONTACT]: "providerContact",
  [ACCOUNT_ROLE_CARESEEKER_CARE]: "care",
  [ACCOUNT_ROLE_CARESEEKER_HOSPITALIZATION]: "hospital",
  // [ACCOUNT_ROLE_PROJECTMANAGER]: "projectManager",
  [ACCOUNT_ROLE_CARESEEKER_REHAB]: "rehab",
  // [ACCOUNT_ROLE_ACCOUNTMANAGER]: "accountManager",
  // [ACCOUNT_ROLE_JOUR_FIXE]: "jourFixe",
  // [ACCOUNT_ROLE_MANAGEMENT]: "management",
  [ACCOUNT_ROLE_SENDER_IT]: "it",
  // [ACCOUNT_ROLE_SALES]: "sales",
  [ACCOUNT_ROLE_REPORT]: "report",
  [ACCOUNT_ROLE_SENDER_API_CREATE_PATIENT]: "senderApi",
  [ACCOUNT_ROLE_COST_APPROVER]: "costApprover",
  [ACCOUNT_ROLE_TRANSPORT]: "transport",
  [ACCOUNT_ROLE_MEDICAL_SUPPLIES]: "medicalSupplies",
  [ACCOUNT_ROLE_CARESEEKER_USER_MANAGEMENT]: "careseekerUserManagement",
  [ACCOUNT_ROLE_ADMIN_EDIT_CONSULTANT]: "adminEditConsultants",
  [ACCOUNT_ROLE_CARESEEKER_SSO_USER_MANAGEMENT]: "careseekerSSOUserManagement",
  [ACCOUNT_ROLE_RECEIVER_ANALYTICS]: "analytics",
  [ACCOUNT_ROLE_RECEIVER_ADMISSIONS]: "admissions",
} as const;

const patientTypeOntology = {
  [SEARCH_TYPE_CARE]: "care",
  [SEARCH_TYPE_HOSPITAL]: "hospital",
  [SEARCH_TYPE_REHABILITATION]: "rehab",
  [SEARCH_TYPE_TRANSPORT]: "transport",
  [SEARCH_TYPE_MEDICAL_SUPPLIES]: "medicalSupplies",
  [SEARCH_TYPE_HOME_CARE]: "homeCare",
} as const;

export const deprecatedOntologies: {
  [index in OntologyType]?: {
    [index in number]: string;
  };
} = {
  careproviderStatus: {
    [CAREPROVIDER_TEST_SENT_DEPRECATED]: "test",
    [CAREPROVIDER_PROFILE_FILLED_DEPRECATED]: "filledProfile",
    [CAREPROVIDER_TEST_NOT_ANSWERED_DEPRECATED]: "notAnswered",
  },
  onboardingStatus: {
    [ONBOARDING_NOT_REACHED_DEPRECATED]: "notReached",
    [ONBOARDING_TO_CALL_BACK_DEPRECATED]: "callBackLater",
    [ONBOARDING_PROFILE_FILLED_DEPRECATED]: "profileFilled",
    [ONBOARDING_RESEND_ONBOARDING_EMAIL_DEPRECATED]: "resendOnboardingEmail",
    [ONBOARDING_IT_ISSUES_DEPRECATED]: "technicalIssues",
    [ONBOARDING_COMPLICATED_DEPRECATED]: "complicated",
    [ONBOARDING_OTHER_DEPRECATED]: "other",
    [ONBOARDING_NOT_REACHED_CALLCENTER_DEPRECATED]: "notReached",
    [ONBOARDING_LATE_DEPRECATED]: "later",
    [ONBOARDING_NO_INTEREST_DEPRECATED]: "noInterest",
    [ONBOARDING_NO_COMMENT_DEPRECATED]: "noComment",
    [ONBOARDING_NO_ACTION_FROM_CALLCENTER_DEPRECATED]: "noActionFromCallcenter",
  },
  accountType: {
    [ACCOUNT_TYPE_CALLCENTER_DEPRECATED]: "callcenter",
    [ACCOUNT_TYPE_CAREPROVIDER_DEPRECATED]: "careprovider",
    [ACCOUNT_TYPE_CLINIC_DEPRECATED]: "clinic",
  },
  solution: {
    [SOLUTION_REHAB_DAY_HOSPITALIZATION_WITH_TRANSPORT_DEPRECATED]:
      "rehabDayHospitalizationWithTransport",
  },
  livingSituation: {
    [LIVING_SITUATION_IN_ELDERLY_HOME]: "livingSituationInElderlyHome",
  },
  [ONTOLOGY_FILE_CATEGORY]: {
    [FILE_CATEGORY_ERR]: "err",
    [FILE_CATEGORY_PCR]: "pcr",
    [FILE_CATEGORY_BTR]: "btr",
    [FILE_CATEGORY_IMG]: "img",
    [FILE_CATEGORY_ECG]: "ecg",
    [FILE_CATEGORY_SN]: "sn",
  },
};

export const ontologies = {
  compressionType: {},
  grantsType: {
    [GRANTS_MONEY_FROM_INSURANCE]: "serviceBasedPayout",
    [GRANTS_MONEY_FROM_STATE]: "fixedPayout",
    [GRANTS_COMBINATION_OF_BOTH]: "combinationOfBoth",
  },
  incontinenceHelpType: {},
  injectionType: {},
  position: {},
  careseekerPriority: {},
  dependencyLevel: {},
  invoiceStatus: {},
  invoiceType: {},
  mentalHealthCommunication: {},
  searchPageTableTitle: {},
  shareModes: {},
  technicalEquipment: {},
  trafficLight: {},
  foodHelp: {},
  meals: {},
  [ONTOLOGY_LANGUAGE]: {
    [LANGUAGE_BULGARIAN]: "bulgarian",
    [LANGUAGE_CZECH]: "czech",
    [LANGUAGE_CROATIAN]: "croatian",
    [LANGUAGE_DANISH]: "danish",
    [LANGUAGE_DUTCH]: "dutch",
    [LANGUAGE_ENGLISH]: "english",
    // [LANGUAGE_ESTONIAN]: "estonian",
    [LANGUAGE_FINNISH]: "finnish",
    [LANGUAGE_FRENCH]: "french",
    [LANGUAGE_GERMAN]: "german",
    [LANGUAGE_GREEK]: "greek",
    [LANGUAGE_HUNGARIAN]: "hungarian",
    [LANGUAGE_ITALIAN]: "italian",
    // [LANGUAGE_LATVIAN]: "latvian",
    [LANGUAGE_LITHUNIAN]: "lithuanian",
    // [LANGUAGE_MALTESE]: "maltese",
    [LANGUAGE_POLISH]: "polish",
    [LANGUAGE_PORTUGUESE]: "portuguese",
    [LANGUAGE_ROMANIAN]: "romanian",
    // [LANGUAGE_SLOVAK]: "slovak",
    // [LANGUAGE_SLOVENE]: "slovene",
    [LANGUAGE_SPANISH]: "spanish",
    [LANGUAGE_SWEDISH]: "swedish",
    [LANGUAGE_RUSSIAN]: "russian",
    [LANGUAGE_AFGHAN]: "afghan",
    [LANGUAGE_ALBANIAN]: "albanian",
    [LANGUAGE_AMHARIC]: "amharic",
    [LANGUAGE_ARABIC]: "arabic",
    [LANGUAGE_AZERBAIJANI]: "azerbaijani",
    [LANGUAGE_BENGAL]: "bengal",
    [LANGUAGE_BOSNIAN]: "bosnian",
    [LANGUAGE_CANTONESE]: "cantonese",
    [LANGUAGE_GEORGIAN]: "georgian",
    [LANGUAGE_HEBREW]: "hebrew",
    [LANGUAGE_HINDI]: "hindi",
    [LANGUAGE_INDONESIAN]: "indonesian",
    [LANGUAGE_JAPANESE]: "japanese",
    [LANGUAGE_KOREAN]: "korean",
    [LANGUAGE_KURDISH]: "kurdish",
    [LANGUAGE_MANDARIN]: "mandarin",
    [LANGUAGE_NORWEGIAN]: "norwegian",
    [LANGUAGE_PERSIAN]: "persian",
    [LANGUAGE_SERBIAN]: "serbian",
    [LANGUAGE_SIGN]: "sign",
    [LANGUAGE_SWAHILI]: "swahili",
    [LANGUAGE_THAI]: "thai",
    [LANGUAGE_TIGRINYA]: "tigrinya",
    [LANGUAGE_TURKISH]: "turkish",
    [LANGUAGE_URKRAINIAN]: "ukrainian",
    [LANGUAGE_URDU]: "urdu",
    [LANGUAGE_VIETNAMESE]: "vietnamese",
  },
  [ONTOLOGY_GENDER]: {
    [GENDER_MALE]: "male",
    [GENDER_FEMALE]: "female",
    [GENDER_OTHER]: "diverse",
  },
  // to be deprecated on release of new rehab forms
  [ONTOLOGY_GENDER_EXTENDED]: {
    1: "male",
    2: "female",
    3: "withoutEntry",
    4: "diverse",
  },
  confession: {},
  roomType: {
    [ROOM_TYPE_SINGLE_STANDARD]: "single",
    [ROOM_TYPE_DOUBLE_ROOM]: "double",
    [ROOM_TYPE_APPARTMENT]: "apartment",
    [ROOM_TYPE_NO_PREFERENCE]: "no_preference",
    [ROOM_TYPE_BARRIER_FREE]: "barrierFree",
    [ROOM_TYPE_ACCOMPANYING_PERSON]: "accompanyingPerson",
    [ROOM_TYPE_ACCOMPANYING_CHILD]: "accompanyingChild",
    [ROOM_TYPE_ACCOMPANYING_SIBLING]: "accompanyingSibling",
  },
  facility: {
    [FACILITY_MOBILE_CARE]: "mobileCare",
    [FACILITY_FULLTIME_MOBILE_CARE]: "fullTimeMobileCare",
    [FACILITY_COMPANIONSHIP]: "companionship",
    [FACILITY_FULLTIME_COMPANIONSHIP]: "fullTimeCompanionship",
    [FACILITY_DAY_CARE]: "dayCare",
    [FACILITY_SHORT_TERM_CARE]: "shortTermStaticCare",
    [FACILITY_STATIC_CARE]: "staticCare",
    [FACILITY_DAY_HOSPITALIZATION]: "dayHospitalization",
    [FACILITY_FULL_HOSPITALIZATION]: "fullHospitalization",
    [FACILITY_SHARED_FLAT]: "sharedFlat",
    [FACILITY_REHAB_DAY_HOSPITALIZATION]: "rehabDayHospitalization",
    [FACILITY_REHAB_FULL_HOSPITALIZATION]: "rehabFullHospitalization",
    [FACILITY_REHAB_MOBILE]: "rehabMobile",
    [FACILITY_TAXI]: "taxi",
    [FACILITY_RENTAL_CAR_WHEELCHAIR]: "taxiWheelchair",
    [FACILITY_TAXI_CARRY_CHAIR]: "taxiCarryChair",
    [FACILITY_RENTAL_CAR_LYING_DOWN]: "taxiLyingDown",
    [FACILITY_KTW_MEDICAL_CARE]: "ambulance",
    [FACILITY_MEDICAL_SUPPLIES]: "medicalSupplies",
    [FACILITY_HOME_CARE]: "medicalSuppliesHomeCare",
    [FACILITY_KTW_WHEELCHAIR]: "ambulanceWheelchair",
    [FACILITY_KTW_CARRY_CHAIR]: "ambulanceCarryChair",
    [FACILITY_KTW_LYING_DOWN]: "ambulanceLyingDown",
    [FACILITY_PURE_INTENSIVE_CARE]: "pureIntensiveCare",
    [FACILITY_HOSPICE_ASSOCIATION]: "hospice",
    [FACILITY_HOSPICE_PARTIALLY_MOBILE]: "hospicePartiallyMobile",
    [FACILITY_HOSPICE_MOBILE]: "hospiceMobile",
    [FACILITY_HOSPICE_STATIC]: "hospiceStatic",
    [FACILITY_ASSISTED_LIVING]: "assistedLiving",
    [FACILITY_NIGHT_CARE_MOBILE]: "nightCareMobile",
    [FACILITY_NIGHT_CARE_STATIC]: "nightCareStatic",
    [FACILITY_DAILY_LIVING_SUPPORT_SERVICES]: "dailyLivingSupportServices",
  },
  [ONTOLOGY_SERVICE]: {
    [SERVICE_COMPANIONSHIP]: "companionship",
    [SERVICE_BASIC_CARE]: "basicCare",
    [SERVICE_BASIC_MEDICAL_CARE]: "basicMedicalCare",
    [SERVICE_TRACHEOSTOMY]: "helpToBreathe",
    [SERVICE_PALLIATIVE_CARE]: "palliativeCare",
    [SERVICE_PSYCHIATRIC_CARE]: "psychiatricCare",
    [SERVICE_DEMENTIA]: "dementia",
    [SERVICE_CLOSED_AREA]: "closedArea",
    [SERVICE_CARE_PROTECTED_AREA]: "protectedAreaCare",
    [SERVICE_PALLIATIVE_CERTIFICATE]: "palliativeCertificate",
    [SERVICE_SPECIALISED_INTENSIVE_NURSE]: "specialisedIntensiveNurse",
    [SERVICE_DIALYSIS]: "dialysis",
    [SERVICE_PARENTERAL_NUTRITRION]: "parenteralNutrition",
    [SERVICE_VISUALLY_IMPAIRED]: "visuallyImpaired",
    [SERVICE_HEARING_IMPAIRED]: "hearingImpaired",
    [SERVICE_ENTERALNUTRITION]: "enteralNutrition",
    [SERVICE_PROTECTEDAREA]: "protectedArea",
    [SERVICE_PEOPLEWITHORIENTATIONDISORDER]: "peopleWithOrientationDisorder",
    [SERVICE_HELPWITHEATING]: "helpWithEating",
    [SERVICE_PEOPLEWITHADDICTIONS]: "peopleWithAddictions",
    [SERVICE_PEOPLEWHOAREDANGEROUS]: "peopleWhoAreDangerous",
    [SERVICE_TAKINGOVERBODYCARE]: "takingOverBodyCare",
    [SERVICE_MONITORINGCIRCULATION]: "monitoringCirculation",
    [SERVICE_MONITORINGBREATHING]: "monitoringBreathing",
    [SERVICE_MONITORINGMETABOLISM]: "monitoringMetabolism",
    [SERVICE_INVASIVEVENTILATION]: "invasiveVentilation",
    [SERVICE_NONINVASIVEVENTILATION]: "nonInvasiveVentilation",
    [SERVICE_OXYGENTHERAPY]: "oxygenTherapy",
    [SERVICE_STATIC_CARE_DISABLED_PATIENTS]: "staticCareDisabledPatients",
  },
  [ONTOLOGY_FILE_CATEGORY]: {
    [FILE_CATEGORY_MP]: "mp",
    [FILE_CATEGORY_RBA]: "rba",
    [FILE_CATEGORY_MR]: "mr",
    [FILE_CATEGORY_CTB]: "ctb",
    [FILE_CATEGORY_MDL]: "mdl",
    [FILE_CATEGORY_BIX]: "bix",
    [FILE_CATEGORY_CC]: "cc",
    [FILE_CATEGORY_AND]: "and",
    [FILE_CATEGORY_AD]: "ad",
    [FILE_CATEGORY_PR]: "pr",
    [FILE_CATEGORY_PRE]: "pre",
    [FILE_CATEGORY_RER]: "rer",
    [FILE_CATEGORY_WP]: "wp",
    [FILE_CATEGORY_MSR]: "msr",
    [FILE_CATEGORY_GI]: "gi",
    [FILE_CATEGORY_APF]: "apf",
    [FILE_CATEGORY_ISO]: "iso",
    [FILE_CATEGORY_FSO]: "fso",
  },
  [ONTOLOGY_FILE_SHARE_MODE]: {
    [FILE_SHARE_MODE_ALL]: "all",
    [FILE_SHARE_MODE_SELECTED]: "selected",
    [FILE_SHARE_MODE_NONE]: "none",
  },
  ageOfPatient: {},
  [ONTOLOGY_FILE_ERROR]: {
    [FILE_ERROR_FILE_SIZE]: "fileSize",
    [FILE_ERROR_FILE_TYPE]: "fileType",
  },
  [ONTOLOGY_TRANSPORT_TYPE]: {
    [TRANSPORT_TYPE_AMBULANCE_LYING_DOWN]: "ambulanceLyingDown",
    [TRANSPORT_TYPE_AMBULANCE_SITTING]: "ambulanceSitting",
    [TRANSPORT_TYPE_PUBLIC_TRANSPORT]: "publicTransport",
    [TRANSPORT_TYPE_TAXI]: "taxi",
    [TRANSPORT_TYPE_PERSONAL_CAR]: "personalCar",
    [TRANSPORT_TYPE_EMERGENCY_VEHICLE]: "emergencyVehicle",
    [TRANSPORT_TYPE_HELICOPTER]: "helicopter",
  },
  [ONTOLOGY_SOLUTION]: {
    [SOLUTION_MOBILE_CARE]: "mobileCare",
    [SOLUTION_FULL_TIME_MOBILE_CARE]: "fullTimeMobileCare",
    [SOLUTION_DAY_CARE]: "dayCare",
    [SOLUTION_SHORT_TERM_STATIC_CARE]: "shortTermStaticCare",
    [SOLUTION_STATIC_CARE]: "staticCare",
    [SOLUTION_DAY_HOSPITALIZATION]: "dayHospitalization",
    [SOLUTION_FULL_HOSPITALIZATION]: "fullHospitalization",
    [SOLUTION_HELP_AT_HOME]: "helpAtHome",
    [SOLUTION_REHAB_FULL_HOSPITALIZATION]: "rehabFullHospitalization",
    [SOLUTION_REHAB_DAY_HOSPITALIZATION]: "rehabDayHospitalization",
    [SOLUTION_REHAB_MOBILE]: "rehabMobile",
    [SOLUTION_SHARED_FLAT]: "sharedFlat",
    [SOLUTION_TAXI]: "taxi",
    [SOLUTION_RENTAL_CAR_WHEELCHAIR]: "taxiWheelchair",
    [SOLUTION_RENTAL_CAR_CARRY_CHAIR]: "taxiCarryChair",
    [SOLUTION_RENTAL_CAR_LYING_DOWN]: "taxiLyingDown",
    [SOLUTION_KTW_WITH_MEDICAL_CARE]: "ambulance",
    [SOLUTION_MEDICAL_SUPPLIES]: "medicalSupplies",
    [SOLUTION_HOME_CARE]: "homeCare",
    [SOLUTION_KTW_WHEELCHAIR]: "ambulanceWheelchair",
    [SOLUTION_KTW_CARRY_CHAIR]: "ambulanceCarryChair",
    [SOLUTION_KTW_LYING_DOWN]: "ambulanceLyingDown",
    [SOLUTION_HOSPICE_ASSOCIATION]: "hospice",
    [SOLUTION_HOSPICE_STATIC]: "hospiceStatic",
    [SOLUTION_HOSPICE_PARTIALLY_MOBILE]: "hospicePartiallyMobile",
    [SOLUTION_HOSPICE_MOBILE]: "hospiceMobile",
    [SOLUTION_NIGHT_CARE_STATIC]: "nightCareStatic",
    [SOLUTION_NIGHT_CARE_MOBILE]: "nightCareMobile",
    [SOLUTION_ASSISTED_LIVING]: "assistedLiving",
    [SOLUTION_DAILY_LIVING_SUPPORT_SERVICES]: "dailyLivingSupportServices",
  },
  [ONTOLOGY_SOLUTION_B2C]: {
    [SOLUTION_MOBILE_CARE]: "mobileCare",
    [SOLUTION_SHORT_TERM_STATIC_CARE]: "shortTermStaticCare",
    [SOLUTION_STATIC_CARE]: "staticCare",
    [SOLUTION_HELP_AT_HOME]: "helpAtHome",
  },
  [ONTOLOGY_MARITAL_STATUS]: {
    [MARITAL_STATUS_SINGLE]: "single",
    [MARITAL_STATUS_MARRIED]: "married",
    [MARITAL_STATUS_DIVORCED]: "divorced",
    [MARITAL_STATUS_WIDOWED]: "widowed",
  },
  [ONTOLOGY_EMPLOYMENT_STATUS]: {
    [EMPLOYMENT_STATUS_NOT_EMPLOYED]: "notEmployed",
    [EMPLOYMENT_STATUS_TRAINEE]: "trainee",
    [EMPLOYMENT_STATUS_UNSKILLED_EMPLOYEE]: "unskilledEmployee",
    [EMPLOYMENT_STATUS_SEMI_SKILLED_WORKER]: "semiSkilledWorker",
    [EMPLOYMENT_STATUS_SKILLED_WORKER]: "skilledWorker",
    [EMPLOYMENT_STATUS_MASTER_FOREMAN]: "masterForeman",
    [EMPLOYMENT_STATUS_EMPLOYEE]: "employee",
    [EMPLOYMENT_STATUS_CIVIL_SERVANT]: "civilServant",
    [EMPLOYMENT_STATUS_SELF_EMPLOYED]: "selfEmployed",
  },
  [ONTOLOGY_PRIOR_EMPLOYMENT_STATUS]: {
    [PRIOR_EMPLOYMENT_NOT_WORKING]: "notWorking",
    [PRIOR_EMPLOYMENT_FULL_TIME_NO_ROTATING_SHIFT]: "fullTimeWithShifts",
    [PRIOR_EMPLOYMENT_FULL_TIME_ROTATING_SHIFT]: "fullTimeNoShifts",
    [PRIOR_EMPLOYMENT_WITH_NIGHT_SHIFTS]: "wihtNightShifts",
    [PRIOR_EMPLOYMENT_PART_TIME_LESS_THAN_HALF_DAY]: "partTimeLessThanHalfDay",
    [PRIOR_EMPLOYMENT_PART_TIME_MORE_THAN_HALF_DAY]: "partTimeMoreThanHalfDay",
    [PRIOR_EMPLOYMENT_HOUSEHOLD_CARE]: "householdCare",
    [PRIOR_EMPLOYMENT_UNEMPLOYED_REGISTERED]: "notEmployedRegistered",
    [PRIOR_EMPLOYMENT_WORK_AT_HOME]: "workAtHome",
    [PRIOR_EMPLOYMENT_WORKSHOP_FOR_DISABLED_PEOPLE]:
      "workshopForDisabledPeople",
  },
  [ONTOLOGY_SPECIALIZATIONS]: {
    [SPECIALIZATION_ANGIOLOGY]: "angiology",
    [SPECIALIZATION_DERMATOLOGY]: "dermatology",
    [SPECIALIZATION_GASTROENTEROLOGY]: "gastroenterology",
    [SPECIALIZATION_GERIATRICS]: "geriatrics",
    [SPECIALIZATION_INTERNAL_MEDICINE]: "internalMedicine",
    [SPECIALIZATION_CARDIOLOGY]: "cardiology",
    [SPECIALIZATION_PARENTS_AND_CHILD_REHAB]: "parentsAndChildRehab",
    [SPECIALIZATION_NEUROLOGY]: "neurology",
    [SPECIALIZATION_ONCOLOGY]: "oncology",
    [SPECIALIZATION_ORTHOPEDICS]: "orthopedics",
    [SPECIALIZATION_ORTHOGERIATRICS]: "orthogeriatrics",
    [SPECIALIZATION_PEDIATRICS]: "pediatrics",
    [SPECIALIZATION_PHASEB]: "phaseB",
    [SPECIALIZATION_PHASEC]: "phaseC",
    [SPECIALIZATION_PHASED]: "phaseD",
    [SPECIALIZATION_PNEUMOLOGY]: "pneumology",
    [SPECIALIZATION_PSYCHOSOMATIC]: "psychosomatic",
    [SPECIALIZATION_RHEUMATISM]: "rheumatism",
    [SPECIALIZATION_ADDICTION]: "addiction",
    [SPECIALIZATION_UROLOGY]: "urology",
    [SPECIALIZATION_ACUTE_GERIATRICS]: "acuteGeriatrics",
    // [SPECIALIZATION_EARLY_REHAB]: "earlyRehab",
    [SPECIALIZATION_PHASECPLUS]: "phaseCplus",
    [SPECIALIZATION_NEUROPEDIATRIY]: "neuroPediatry",
    [SPECIALIZATION_ONCOLOGY_BREAST_GLANDS]: "oncologyBreastGlands",
    [SPECIALIZATION_ONCOLOGY_CENTRAL_NERVOUS_SYSTEM]:
      "oncologyCentralNervousSystem",
    [SPECIALIZATION_ONCOLOGY_DIGESTIVE_ORGANS]: "oncologyDigestiveOrgans",
    [SPECIALIZATION_ONCOLOGY_FEMALE_GENITAL]: "oncologyFemaleGenital",
    [SPECIALIZATION_ONCOLOGY_LOCOMOTOR_ORGANS]: "oncologyLocomotorOrgans",
    [SPECIALIZATION_ONCOLOGY_MALIGNANT_SYSTEMIC_DISEASES]:
      "oncologyMalignantSystemicDiseases",
    [SPECIALIZATION_ONCOLOGY_PHARYNX_LARYNX]: "oncologyPharync",
    [SPECIALIZATION_ONCOLOGY_RESPIRATORY_ORGANS]: "oncologyRespiratoryOrgans",
    [SPECIALIZATION_ONCOLOGY_SKIN]: "oncologySkin",
    [SPECIALIZATION_ONCOLOGY_THYROID]: "oncologyThyroid",
    [SPECIALIZATION_ONCOLOGY_KIDNEY_URINARY_MALE_GENITAL]:
      "oncologyUrinaryKidneyMaleGenitals",
    [SPECIALIZATION_NEPHROLOGY]: "nephrology",
    [SPECIALIZATION_GYNAECOLOGY]: "gynaecology",
    [SPECIALIZATION_ENDOCRINOLOGY]: "endocrinology",
    [SPECIALIZATION_ICU_LOW_CORONA]: "icuCorona",
    [SPECIALIZATION_ICU_HIGH_CORONA]: "icuHighCorona",
    [SPECIALIZATION_ICU_ECMO_CORONA]: "icuECMOCorona",
    [SPECIALIZATION_NORMAL_STATION]: "normalStation",
    [SPECIALIZATION_INFECTIOUS_DISEASE]: "infectiousDisease",
    [SPECIALIZATION_RADIOLOGY]: "radiology",
    [SPECIALIZATION_TRAUMA_SURGERY]: "traumaSurgery",
    [SPECIALIZATION_GENERAL_SURGERY]: "generalSurgery",
    [SPECIALIZATION_ANESTHESIOLOGY]: "anesthesiology",
    [SPECIALIZATION_ICU_NEUROVASCULAR]: "icuNeurovascular",
    [SPECIALIZATION_ICU_CARDIOLOGY]: "icuCardiology",
    [SPECIALIZATION_ICU_GASTROLOGY]: "icuGastrology",
    [SPECIALIZATION_COGNITIVE_BEHAVIORAL_UNIT]: "cognitiveBehavioralUnit",
    [SPECIALIZATION_PALLIATIVE_CARE]: "palliativeCare",
    [SPECIALIZATION_OPHTHALMOLOGY]: "ophthalmology",
    [SPECIALIZATION_PSYCHIATRY]: "psychiatry",
    [SPECIALIZATION_DIABETIC_FOOT]: "diabeticFoot",
    [SPECIALIZATION_SURGICAL_RESUSCITATION]: "surgicalResuscitation",
    [SPECIALIZATION_MEDICAL_REANIMATION]: "medicalReanimation",
    [SPECIALIZATION_ENT]: "ent",
    [SPECIALIZATION_NEUROSURGERY]: "neurosurgery",
    [SPECIALIZATION_HAEMATOLOGY]: "haematology",
    [SPECIALIZATION_DIABETOLOGY]: "diabetology",
    [SPECIALIZATION_VASCULAR_SURGERY]: "vascularSurgery",
    [SPECIALIZATION_THORACIC_SURGERY]: "thoracicSurgery",
    [SPECIALIZATION_DIGESTIVE_SURGERY]: "digestiveSurgery",
    [SPECIALIZATION_ALLERGOLOGY]: "allergology",
    [SPECIALIZATION_HEPATOLOGY]: "hepatology",
    [SPECIALIZATION_PHYSICAL_MEDICINE]: "physicalMedicine",
    [SPECIALIZATION_PLASTIC_SURGERY]: "plasticSurgery",
    [SPECIALIZATION_MAXILLOFACIAL_SURGERY]: "maxillofacialSurgery",
    [SPECIALIZATION_ADDICTOLOGY]: "addictology",
    [HOME_CARE_SPECIALIZATION_TRACHEOSTOMY_THERAPY]: "tracheostomyTherapy",
    [HOME_CARE_SPECIALIZATION_WOUND_CARE]: "woundCare",
    [HOME_CARE_SPECIALIZATION_PARENTERAL_NUTRITION]: "parenteralNutrition",
    [HOME_CARE_SPECIALIZATION_ENTERAL_NUTRITION]: "enteralNutrition",
    [HOME_CARE_SPECIALIZATION_RESPIRATORY_THERAPY]: "respiratoryTherapy",
    [HOME_CARE_SPECIALIZATION_VENTILATION]: "ventilationOxygen",
    [HOME_CARE_SPECIALIZATION_INCONTINENCE]: "incontinence",
    [HOME_CARE_SPECIALIZATION_MONITORING]: "monitoring",
    [HOME_CARE_SPECIALIZATION_PAIN_THERAPY]: "painTherapy",
    [HOME_CARE_SPECIALIZATION_INFUSION_THERAPY]: "infusionTherapy",
    [HOME_CARE_SPECIALIZATION_DECUBITUS_PROPHYLAXIS]: "decubitusProphylaxis",
    [HOME_CARE_SPECIALIZATION_COMPRESSION_THERAPY]: "compressionTherapy",
    [HOME_CARE_SPECIALIZATION_OXYGEN]: "oxygen",
    [HOME_CARE_SPECIALIZATION_INCONTINENCE_DISCHARGING]:
      "incontinenceDischarging",
    [HOME_CARE_SPECIALIZATION_STOMA]: "stoma",
    [SPECIALIZATION_WEANING]: "weaning",
    [SPECIALIZATION_CARDIOLOGICAL_EARLY_REHA]:
      "cardiologicalEarlyRehabilitation",
    [SPECIALIZATION_INTERNAL_MEDICAL_EARLY_REHA]:
      "internalMedicalEarlyRehabilitation",
    [SPECIALIZATION_PNEUMOLOGICAL_EARLY_REHA]:
      "pneumologicalEarlyRehabilitation",
    [SPECIALIZATION_OBESITY]: "obesity",
    [SPECIALIZATION_ORTHOPEDICS_UPPER_LIMBS]: "orthopedicsUpperLimbs",
    [SPECIALIZATION_ORTHOPEDICS_LOWER_LIMBS]: "orthopedicsLowerLimbs",
    [SPECIALIZATION_ORTHOPEDICS_SPINE]: "orthopedicsSpine",
    [SPECIALIZATION_ORTHOPEDICS_SOS_MAINS]: "orthopedicsSosMains",
  },
  [ONTOLOGY_SALUTATION]: {
    [GENDER_MALE]: "monsieur",
    [GENDER_FEMALE]: "madame",
    [GENDER_OTHER]: "other",
  },
  [ONTOLOGY_CARE_DURATIONS]: {
    [CARE_DURATION_UNKNOWN]: "unknown",
    [CARE_DURATION_ONE_DAY]: "oneDay",
    [CARE_DURATION_ONE_WEEK]: "oneWeek",
    [CARE_DURATION_TWO_WEEKS]: "twoWeeks",
    [CARE_DURATION_THREE_WEEKS]: "threeWeeks",
    [CARE_DURATION_ONE_MONTH]: "oneMonth",
    [CARE_DURATION_TWO_MONTHS]: "twoMonths",
    [CARE_DURATION_THREE_MONTHS]: "threeMonths",
    [CARE_DURATION_FOUR_MONTHS]: "fourMonths",
    [CARE_DURATION_FIVE_MONTHS]: "fiveMonths",
    [CARE_DURATION_SIX_MONTHS_PLUS]: "sixMonthsPlus",
  },
  [ONTOLOGY_CANDIDATES_STATUS]: {
    [CANDIDATES_STATUS_FAILED]: "failed",
    [CANDIDATES_STATUS_NOT_STARTED]: "notStarted",
    [CANDIDATES_STATUS_PENDING]: "inProgress",
    [CANDIDATES_STATUS_SUCCESS]: "success",
  },
  [ONTOLOGY_CARE_LEVEL]: {
    [CARELEVEL_ONE]: "one",
    [CARELEVEL_TWO]: "two",
    [CARELEVEL_THREE]: "three",
    [CARELEVEL_FOUR]: "four",
    [CARELEVEL_FIVE]: "five",
    [CARELEVEL_NONE]: "none",
    [CARELEVEL_UNKNOWN]: "unknown",
  },
  genderLetter: {},
  careproviderStatus: {
    [CAREPROVIDER_CREATED]: "created",
    [CAREPROVIDER_ACTIVE]: "active",
    [CAREPROVIDER_INACTIVE]: "inactive",
    [CAREPROVIDER_INVALID]: "invalid",
    [CAREPROVIDER_DUPLICATE]: "duplicate",
    [CAREPROVIDER_PENDING_ACCOUNT_ACTIVATION]: "pendingAccountActivation",
    [CAREPROVIDER_TRASH]: "trash",
  },
  companyStatus: {
    [COMPANY_STATUSES.COMPANY_STATUS_INACTIVE]: "no",
    [COMPANY_STATUSES.COMPANY_STATUS_DUPLICATE]: "duplicate",
    [COMPANY_STATUSES.COMPANY_STATUS_CREATED]: "created",
  },
  companyType: {
    [COMPANY_TYPES.COMPANY_TYPE_FACILITY]: "facility",
    [COMPANY_TYPES.COMPANY_TYPE_ORGANIZATION]: "organization",
  },
  onboardingStatus: {
    [ONBOARDING_SELF_ONBOARDING]: "selfOnboarding",
    [ONBOARDING_TO_CALL]: "toCall",
    [ONBOARDING_CHAIN]: "chain",
    [ONBOARDING_PREONBOARDING]: "preOnboarding",
    [ONBOARDING_MISSING_INFORMATION]: "missingInformation",
    [ONBOARDING_INFO_PHASE]: "infoPhase",
    [ONBOARDING_CONSIDERATION_PHASE]: "considerationPhase",
    [ONBOARDING_ADMINISTRATION_PHASE]: "administrationPhase",
    [ONBOARDING_MANUALLY_ADDED_BY_SENDER]: "manuallyAdded",
  },
  [ONTOLOGY_INSURANCE_TYPE]: {
    [COST_PAYER_TYPE_PUBLIC_HEALTH_INSURANCE]: "public",
    [COST_PAYER_TYPE_PRIVATE_HEALTH_INSURANCE]: "private",
  },
  declinedReason: {},
  grantStatus: {
    [GRANT_STATUS_NOT_APPLIED]: "notApplied",
    [GRANT_STATUS_APPLIED_FOR]: "applied",
    [GRANT_STATUS_EXISTS]: "exists",
  },
  livingSituation: {
    [LIVING_SITUATION_ALONE]: "livingSituationAlone",
    [LIVING_SITUATION_WITH_FAMILY]: "livingSituationWithFamily",
    [LIVING_SITUATION_CAREHOME]: "livingSituationCarehome",
    [LIVING_SITUATION_OTHER]: "livingSituationOther",
    [LIVING_SITUATION_SHARED_FLAT]: "livingSituationSharedFlat",
  },
  [ONTOLOGY_ORIENTATION]: {
    [ORIENTATION_ORIENTATED]: "orientated",
    [ORIENTATION_LIMITED]: "limited",
    [ORIENTATION_DISORIENTED]: "disoriented",
  },
  careseekerStatus: {
    [CARESEEKER_STATUS_ACTIVE]: "active",
    [CARESEEKER_STATUS_DUPLICATE]: "duplicate",
    [CARESEEKER_STATUS_INACTIVE]: "inactive",
    [CARESEEKER_STATUS_SALES]: "salesa",
    [CARESEEKER_STATUS_TRASH]: "trash",
  },
  careseekerModuleStatus: {
    [CARESEEKER_STATUS_CREATED]: "created",
    [CARESEEKER_STATUS_ACTIVE]: "active",
    [CARESEEKER_STATUS_INACTIVE]: "inactive",
    [CARESEEKER_STATUS_ONBOARDING]: "onboarding",
  },
  [ONTOLOGY_MOTIVATION]: {
    [MOTIVATION_GOOD]: "good",
    [MOTIVATION_MODERATE]: "moderate",
    [MOTIVATION_BAD]: "bad",
  },
  [ONTOLOGY_COMMUNICATION]: {
    [COMMUNICATION_GOOD]: "good",
    [COMMUNICATION_MODERATE]: "moderate",
    [COMMUNICATION_BAD]: "bad",
  },
  [ONTOLOGY_MOBILITY]: {
    [MOBILITY_WALK_WITHOUT_HELP]: "walkWithoutHelp",
    [MOBILITY_WALKING_STICK]: "walkingStick",
    [MOBILITY_WALKING_FRAME]: "walkingFrame",
    [MOBILITY_WHEELCHAIR]: "wheelChair",
    [MOBILITY_MEDICALISED_BED]: "medicalisedBed",
    [MOBILITY_ROLLATOR]: "rollator",
    [MOBILITY_WALK_WITH_HELP]: "walkWithHelp",
    [MOBILITY_CRUTCH]: "crutch",
  },
  [ONTOLOGY_REHABILITATION_POTENTIAL]: {
    [REHABILITATION_POTENTIAL_EVALUATION_EXTREMELY_POSITIVE]:
      "evaluationExtremelyPositive",
    [REHABILITATION_POTENTIAL_EVALUATION_POSITIVE]: "evaluationPositive",
    [REHABILITATION_POTENTIAL_EVALUATION_MODERATE]: "evaluationModerate",
    [REHABILITATION_POTENTIAL_EVALUATION_NEGATIVE]: "evaluationNegative",
    [REHABILITATION_POTENTIAL_EVALUATION_EXTREMELY_NEGATIVE]:
      "evaluationExtremelyNegative",
  },
  receiverInactiveReason: {
    [RECEIVER_INACTIVE_REASONS.NO_INTEREST_DO_NOT_CONTACT]:
      "noInterestDoNotContact",
    [RECEIVER_INACTIVE_REASONS.CLOSED]: "closed",
    [RECEIVER_INACTIVE_REASONS.NEVER_REACHED_AGAIN]: "neverReachedAgain",
    [RECEIVER_INACTIVE_REASONS.UNSUPPORTED_FACILITY]: "unsupportedFacility",
    [RECEIVER_INACTIVE_REASONS.NO_INTEREST_MAYBE_LATER]: "noInterestMaybeLater",
    [RECEIVER_INACTIVE_REASONS.CHURNED]: "churned",
    [RECEIVER_INACTIVE_REASONS.TEMPORARY_CLOSED]: "temporaryClosed",
    [RECEIVER_INACTIVE_REASONS.UNNNEEDED_CHAIN_CENTER]: "unneededChainCenter",
    [RECEIVER_INACTIVE_REASONS.UNNNEEDED_CHAIN_SUBSIDIARY]:
      "unneededChainSubsidiary",
    [RECEIVER_INACTIVE_REASONS.NO_ACCOUNT]: "noAccount",
    [RECEIVER_INACTIVE_REASONS.ASKLEPIOS_INHOUSE_SOLUTION]:
      "asklepiosInhouseSolution",
    [RECEIVER_INACTIVE_REASONS.MEDIAN_INHOUSE_SOLUTION]:
      "medianInhouseSolution",
    [RECEIVER_INACTIVE_REASONS.HIGH_LEVEL_DECISION]: "higherLevelDecision",
  },
  accountType: {
    [ACCOUNT_TYPE_STAFF]: "staff",
    [ACCOUNT_TYPE_API]: "api",
    [ACCOUNT_TYPE_EXTERNAL]: "external",
    [ACCOUNT_TYPE_TOOL]: "tool",
    [ACCOUNT_TYPE_PROVIDER_SEARCH]: "bToC",
  },
  blacklistReceiverRules: {
    [FILTER_SERVICE]: "service",
    [FILTER_STATUS]: "status",
    [FILTER_ZIPCODES]: "zipcodes",
    [FILTER_CAPACITY_START_DATE]: "capacityStartDate",
    [FILTER_CONTACTED]: "filterContacted",
    [FILTER_AGE]: "age",
    [FILTER_DISTANCE]: "distance",
    [FILTER_THROTTLE]: "throttle",
    [FILTER_PREREQUISITE]: "prerequisite",
    [FILTER_SPECIALIZATION]: "specialization",
    [FILTER_SOLUTION]: "solution",
    [FILTER_CAPACITY_BACKOFF]: "capacityBackoff",
    [FILTER_NO_BED_AVAILABLE]: "noBedAvailable",
    [FILTER_REMOVED_SOLUTION]: "removedSolution",
    [FILTER_RECEIVER_BLACKLISTED]: "receiverBlacklisted",
    [FILTER_SENDER_BLACKLISTED]: "senderBlacklisted",
    [FILTER_BLACKLISTED_INSURANCE]: "blacklistedInsurance",
  },
  accountStatus: {
    [ACCOUNT_STATUS_CREATED]: "created",
    [ACCOUNT_STATUS_ACTIVE]: "active",
    [ACCOUNT_STATUS_INACTIVE]: "inactive",
  },
  accountRole: accountRolesOntologies,
  accountRoleShort: accountRolesOntologies,
  emailType: {
    [MAIL_TYPES.AUCTION_REQUEST]: "auctionRequest",
    [MAIL_TYPES.ATTRIBUTION_CONFIRMATION]: "attributionConfirmation",
    [MAIL_TYPES.WELCOME]: "welcomeEmail",
    [MAIL_TYPES.PATIENT_UNAVAILABLE]: "receiverRejected",
    [MAIL_TYPES.NEW_ANSWER]: "newAnswer",
    [MAIL_TYPES.NEW_FILE_FROM_CLINIC]: "newMessageFromClinic",
    [MAIL_TYPES.SEARCH_REMINDER]: "searchReminder",
    [MAIL_TYPES.ATTRIBUTION_CANCELLATION]: "attributionCancellation",
    [MAIL_TYPES.RESET_PASSWORD]: "resetPassword",
    [MAIL_TYPES.CAREPROVIDER_ACCOUNT_INVITATION]: "accountInvited",
    [MAIL_TYPES.CAREPROVIDER_ACCOUNT_ADDED]: "accountAdded",
    [MAIL_TYPES.PROFILE_EDITED_NOTIFICATION]: "profileEdited",
    [MAIL_TYPES.ONBOARDING]: "onboarding",
    [MAIL_TYPES.PASSWORD_CHANGED]: "passwordChanged",
    [MAIL_TYPES.SOCIAL_WORKER_ASSIGNED]: "assigneeChanged",
  },
  shortTermGrant: {
    [SHORT_TERM_CARE_GRANT_42]: "paragraph42",
    [SHORT_TERM_CARE_GRANT_39]: "paragraph39",
    [SHORT_TERM_CARE_GRANT_COMBINATION]: "combination",
  },
  reliefServices: {
    [RELIEF_SERVICES_PARAGRAPH_A]: "paragraphA",
    [RELIEF_SERVICES_PARAGRAPH_B]: "paragraphB",
  },
  [ONTOLOGY_ACADEMIC_TITLE]: {
    [ACADEMIC_TITLE_DOCTOR]: "doctor",
    [ACADEMIC_TITLE_DOCTOR_MULTIPLEX]: "doctorMultiplex",
    [ACADEMIC_TITLE_DOCTOR_MEDICINE]: "doctorMedicine",
    [ACADEMIC_TITLE_DOCTOR_DOCTOR_MEDICINE]: "doctorDoctorMedicine",
    [ACADEMIC_TITLE_PROFESSOR]: "professor",
    [ACADEMIC_TITLE_PROFESSOR_DOCTOR]: "professorDoctor",
    [ACADEMIC_TITLE_PROFESSOR_ORDINARIUS]: "professorOrdinarius",
  },
  [ONTOLOGY_FORM_YES_NO]: {
    [FORM_YES_NO_POSITIVE]: "yes",
    [FORM_YES_NO_NEGATIVE]: "no",
  },
  infectionAndGerms: {
    [INFECTION_AND_GERMS_REQUIRES_ISOLATION]: "requires_isolation",
    [INFECTION_AND_GERMS_REQUIRES_MRSA]: "mrsa",
    [INFECTION_AND_GERMS_REQUIRES_THREE_MRGN]: "three_mrgn",
    [INFECTION_AND_GERMS_REQUIRES_FOUR_MRGN]: "four_mrgn",
    [INFECTION_AND_GERMS_REQUIRES_CLOSTRIDIEN]: "clostridien",
    [INFECTION_AND_GERMS_REQUIRES_VRE]: "vre",
  },
  vigilance: {
    [VIGILANCE_AGITATED]: "agitated",
    [VIGILANCE_AWAKE]: "awake",
    [VIGILANCE_CALM]: "calm",
    [VIGILANCE_COMATOSE]: "comatose",
    [VIGILANCE_RESPONSIVE]: "responsive",
    [VIGILANCE_SOMNOLENT]: "somnolent",
    [VIGILANCE_SOPORIFIC]: "soporific",
  },
  eligibilityRejectReason: {
    [ELIGIBILITY_REJECT_NOT_ELIGIBLE]: "patientNotEligible",
    [ELIGIBILITY_REJECT_DIFFERENT_COST_PAYER_RESPONSIBLE]:
      "differentCostPayerResponsible",
    [ELIGIBILITY_REJECT_ANOTHER_FORM_OF_REHAB_SOUGHT]: "anotherFormRehaSought",
    [ELIGIBILITY_REJECT_OTHER]: "otherReason",
  },
  patientType: patientTypeOntology,
  receiverTypeSingular: patientTypeOntology,
  receiverTypePlural: patientTypeOntology,
  receiverTypeSingularPreferred: patientTypeOntology,
  receiverTypePluralPreferred: patientTypeOntology,
  [OTHER_EQUIPMENT]: {
    [TECHNICAL_EQUIPMENT_DIALYSIS]: "dialysis",
    [TECHNICAL_EQUIPMENT_MONITORING_BREATHING]: "monitoringBreathing",
    [TECHNICAL_EQUIPMENT_MONITORING_CIRCULATION]: "monitoringCirculation",
    [TECHNICAL_EQUIPMENT_MONITORING_METABOLISM]: "monitoringMetabolism",
  },
  [IMAGERY]: {
    [TECHNICAL_EQUIPMENT_CTSCAN]: "ctScan",
    [TECHNICAL_EQUIPMENT_DOPPLERS]: "dopplers",
    [TECHNICAL_EQUIPMENT_INTERVENTION_RADIOLOGY]: "interventionalRadiology",
    [TECHNICAL_EQUIPMENT_MRI]: "mri",
    [TECHNICAL_EQUIPMENT_ULTRASOUND]: "ultrasound",
    [TECHNICAL_EQUIPMENT_XRAY]: "xRay",
  },
  [DIGESTIVE_ENDOSCOPY]: {
    [TECHNICAL_EQUIPMENT_COLONOSCOPY]: "colonoscopy",
    [TECHNICAL_EQUIPMENT_ENDOSCOPIC_ULTRASOUND]: "endoscopicUltrasound",
    [TECHNICAL_EQUIPMENT_ERCP]: "ercp",
    [TECHNICAL_EQUIPMENT_GASTROSCOPY]: "gastroscopy",
  },
  [CARDIOVASCULAR_EQUIPMENT]: {
    [TECHNICAL_EQUIPMENT_CARDIAC_MRI]: "cardiacMri",
    [TECHNICAL_EQUIPMENT_CORONARY_SCANNER]: "coronaryScanner",
    [TECHNICAL_EQUIPMENT_CORONAROGRAPHY]: "coronarography",
    [TECHNICAL_EQUIPMENT_EXERCISE_TEST]: "exerciseTest",
    [TECHNICAL_EQUIPMENT_HOLTER_ECG]: "holterEcg",
    [TECHNICAL_EQUIPMENT_RYTHMOLOGY]: "rhythmology",
    [TECHNICAL_EQUIPMENT_TOE]: "toe",
    [TECHNICAL_EQUIPMENT_TTE]: "tte",
  },
  searchTableStatusChips: {
    [REQUEST_CHIP_STATUS_REQUEST_NOT_SEEN]: "requestNotSeen",
    [REQUEST_CHIP_STATUS_REQUEST_SEEN]: "requestSeen",
    [REQUEST_CHIP_STATUS_SENT_MESSAGE_NOT_SEEN]: "sentMessageNotSeen",
    [REQUEST_CHIP_STATUS_SENT_MESSAGE_SEEN]: "sentMessageSeen",
    [REQUEST_CHIP_STATUS_NEW_MESSAGE]: "newMessage",
    [REQUEST_CHIP_STATUS_NEW_FILE]: "newFile",
    [REQUEST_CHIP_STATUS_FAX_SENT]: "faxSent",
    [REQUEST_CHIP_STATUS_PROVIDER_INACTIVE]: "providerInactive",
    [REQUEST_CHIP_STATUS_ONGOING_CHAT]: "ongoingChat",
  },
  [ONTOLOGY_ROWS_PER_PAGE]: {
    [SEARCH_TABLE_ROW_COUNT_10]: "tenRows",
    [SEARCH_TABLE_ROW_COUNT_30]: "thirtyRows",
    [SEARCH_TABLE_ROW_COUNT_50]: "fiftyRows",
  },
  [ONTOLOGY_TABLE_SORTING]: {
    [SEARCH_TABLE_SORTING_DEFAULT]: "sortByDefault",
    [SEARCH_TABLE_SORTING_NAME]: "sortByName",
    [SEARCH_TABLE_SORTING_DISTANCE]: "sortByDistance",
  },
  [ONTOLOGY_INFORMATION_SYSTEM]: {
    [INF_SYS_MYNEVA]: "myneva",
    [INF_SYS_MEDIFOX]: "medifox",
    [INF_SYS_DAN_PRODUKTE]: "danProdukte",
    [INF_SYS_CONNEXT_VIVENDI]: "connextVivendi",
    [INF_SYS_NOVENTI_CARE]: "noventiCare",
    [INF_SYS_CURASOFT]: "curasoft",
    [INF_SYS_SNAP]: "snap",
    [INF_SYS_SINFONIE]: "sinfonie",
    [INF_SYS_SENSO]: "senso",
    [INF_SYS_GODO_SYSTEMS]: "godoSystems",
    [INF_SYS_OTHER]: "other",
  },
  [ONTOLOGY_PROVIDER_SEARCH_REQUEST_STATUS]: {
    [AUCTION_REQUEST_SENT]: "sent",
    [AUCTION_REQUEST_ACCEPTED]: "accepted",
    [AUCTION_REQUEST_DECLINED]: "declined",
    [AUCTION_REQUEST_ATTRIBUTED]: "attributed",
    [AUCTION_REQUEST_REJECTED]: "rejected",
    [AUCTION_REQUEST_UNAVAILABLE]: "unavailable",
    [AUCTION_REQUEST_CREATED]: "created",
  },
  [ONTOLOGY_PAGINATION_RESULT_COUNT]: {
    [PAGINATION_COUNT_10]: "ten",
    [PAGINATION_COUNT_20]: "twenty",
    [PAGINATION_COUNT_30]: "thirty",
  },
  [ONTOLOGY_PROVIDER_SEARCH_NAV_TITLE]: {
    [PROVIDER_SEARCH_BAVARIA]: "bavaria",
  },
  [ONTOLOGY_SERVICE_EXPLANATIONS]: {
    [SERVICE_BASIC_CARE]: "basicCare",
    [SERVICE_BASIC_MEDICAL_CARE]: "basicMedicalCare",
    [SERVICE_COMPANIONSHIP]: "companionship",
    [SERVICE_DEMENTIA]: "dementiaCare",
    [SERVICE_SPECIALISED_INTENSIVE_NURSE]: "intensiveCare",
    [SERVICE_PALLIATIVE_CARE]: "palliativeCare",
    [SERVICE_PROTECTEDAREA]: "protectedArea",
    [SERVICE_CLOSED_AREA]: "closedArea",
    [SERVICE_PSYCHIATRIC_CARE]: "psychiatricCare",
    [SERVICE_PALLIATIVE_CERTIFICATE]: "sapv",
    [SERVICE_STATIC_CARE_DISABLED_PATIENTS]: "staticCareForDisabledPatients",
    [SERVICE_TRACHEOSTOMY]: "tracheostomy",
    [SERVICE_CARE_PROTECTED_AREA]: "protectedArea",
  },
  [ONTOLOGY_UNIVERSAL_SEARCH_RESOURCE]: {
    [UNIVERSAL_SEARCH_RESOURCES.ACCOUNT]: "account",
    [UNIVERSAL_SEARCH_RESOURCES.COMPANY]: "company",
    [UNIVERSAL_SEARCH_RESOURCES.CONSULTANT]: "consultant",
    [UNIVERSAL_SEARCH_RESOURCES.RECEIVER]: "receiver",
    [UNIVERSAL_SEARCH_RESOURCES.SENDER]: "sender",
  },
  [ONTOLOGY_CONSULTANT_STATUS]: {
    [CONSULTANT_STATUS_CREATED]: "created",
    [CONSULTANT_STATUS_ACTIVE]: "active",
    [CONSULTANT_STATUS_INACTIVE]: "inactive",
  },
  [ONTOLOGY_TRANSITIONAL_CARE_REQUEST_STATUS]: {
    [AUCTION_REQUEST_SENT]: "sent",
    [AUCTION_REQUEST_DECLINED]: "declined",
    [AUCTION_REQUEST_REJECTED]: "rejected",
    [AUCTION_REQUEST_ACCEPTED]: "accepted",
  },
  [ONTOLOGY_PRODUCT_FEATURE]: {
    [PRODUCT_FEATURE_TRANSITIONAL_CARE]: "transitionalCare",
    [PRODUCT_FEATURE_ANALYTICS]: "recareAnalytics",
    [PRODUCT_FEATURE_ADMISSIONS]: "recareAdmissions",
  },
} as const;

/**
 * Ensures `ontologies` matches the structure of the ontologies in the Translations in the ACP.
 */
const _ontologiesSyncWithTranslationsCheck: Record<
  keyof Translations["ontologies"],
  Record<number, OntologyStructValue>
> = ontologies;

export const ontologiesOrders: {
  [index in OntologyType]?: {
    [index in number]: number;
  };
} = {
  mobility: {
    [MOBILITY_WALK_WITHOUT_HELP]: 1,
    [MOBILITY_WALK_WITH_HELP]: 2,
    [MOBILITY_WALKING_STICK]: 3,
    [MOBILITY_CRUTCH]: 4,
    [MOBILITY_WALKING_FRAME]: 5,
    [MOBILITY_ROLLATOR]: 6,
    [MOBILITY_WHEELCHAIR]: 7,
    [MOBILITY_MEDICALISED_BED]: 8,
  },
  grantStatus: {
    [GRANT_STATUS_NOT_APPLIED]: 1,
    [GRANT_STATUS_APPLIED_FOR]: 2,
    [GRANT_STATUS_EXISTS]: 3,
  },
  livingSituation: {
    [LIVING_SITUATION_ALONE]: 1,
    [LIVING_SITUATION_WITH_FAMILY]: 2,
    [LIVING_SITUATION_CAREHOME]: 3,
    [LIVING_SITUATION_SHARED_FLAT]: 4,
    [LIVING_SITUATION_OTHER]: 5,
  },
  shortTermGrant: {
    [SHORT_TERM_CARE_GRANT_42]: 1,
    [SHORT_TERM_CARE_GRANT_39]: 2,
    [SHORT_TERM_CARE_GRANT_COMBINATION]: 3,
  },
  reliefServices: {
    [RELIEF_SERVICES_PARAGRAPH_A]: 1,
    [RELIEF_SERVICES_PARAGRAPH_B]: 2,
  },
  [ONTOLOGY_REHABILITATION_POTENTIAL]: {
    [REHABILITATION_POTENTIAL_EVALUATION_EXTREMELY_POSITIVE]: 1,
    [REHABILITATION_POTENTIAL_EVALUATION_POSITIVE]: 2,
    [REHABILITATION_POTENTIAL_EVALUATION_MODERATE]: 3,
    [REHABILITATION_POTENTIAL_EVALUATION_NEGATIVE]: 4,
    [REHABILITATION_POTENTIAL_EVALUATION_EXTREMELY_NEGATIVE]: 5,
  },
  [ONTOLOGY_SALUTATION]: {
    [GENDER_MALE]: 1,
    [GENDER_FEMALE]: 2,
    [GENDER_OTHER]: 3,
  },
  vigilance: {
    [VIGILANCE_AGITATED]: 1,
    [VIGILANCE_CALM]: 2,
    [VIGILANCE_AWAKE]: 3,
    [VIGILANCE_RESPONSIVE]: 4,
    [VIGILANCE_SOMNOLENT]: 5,
    [VIGILANCE_SOPORIFIC]: 6,
    [VIGILANCE_COMATOSE]: 7,
  },
  [ONTOLOGY_TRANSPORT_TYPE]: {
    [TRANSPORT_TYPE_EMERGENCY_VEHICLE]: 1,
    [TRANSPORT_TYPE_AMBULANCE_LYING_DOWN]: 2,
    [TRANSPORT_TYPE_AMBULANCE_SITTING]: 3,
    [TRANSPORT_TYPE_PUBLIC_TRANSPORT]: 4,
    [TRANSPORT_TYPE_TAXI]: 5,
    [TRANSPORT_TYPE_PERSONAL_CAR]: 6,
    [TRANSPORT_TYPE_HELICOPTER]: 7,
  },
  patientType: {
    [SEARCH_TYPE_CARE]: 1,
    [SEARCH_TYPE_REHABILITATION]: 2,
    [SEARCH_TYPE_HOSPITAL]: 3,
    [SEARCH_TYPE_TRANSPORT]: 4,
    [SEARCH_TYPE_MEDICAL_SUPPLIES]: 5,
    [SEARCH_TYPE_HOME_CARE]: 6,
  },
  [ONTOLOGY_FILE_SHARE_MODE]: {
    [FILE_SHARE_MODE_NONE]: 1,
    [FILE_SHARE_MODE_ALL]: 2,
    [FILE_SHARE_MODE_SELECTED]: 3,
  },
  [ONTOLOGY_INFORMATION_SYSTEM]: {
    [INF_SYS_MYNEVA]: 1,
    [INF_SYS_MEDIFOX]: 2,
    [INF_SYS_DAN_PRODUKTE]: 3,
    [INF_SYS_CONNEXT_VIVENDI]: 4,
    [INF_SYS_NOVENTI_CARE]: 5,
    [INF_SYS_CURASOFT]: 6,
    [INF_SYS_SNAP]: 7,
    [INF_SYS_SINFONIE]: 8,
    [INF_SYS_SENSO]: 9,
    [INF_SYS_GODO_SYSTEMS]: 10,
    [INF_SYS_OTHER]: 11,
  },
} as const;

export {
  getKey,
  getName,
  getOntologies,
  getOntology,
  getValueFromTranslation,
  getValues,
  validOntology,
} from "./utils";
